import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MainMenuService {
  private $refreshProfileNotifications = new BehaviorSubject<boolean>(false);
  public refreshProfileNotificationsHook = this.$refreshProfileNotifications.asObservable();

  constructor() {}

  refreshProfileNotifications(): void {
    this.$refreshProfileNotifications.next(true);
  }
}
