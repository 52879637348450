import {Injectable, OnDestroy} from '@angular/core';

@Injectable()
export class ProductLineService implements OnDestroy {
  private productLine: any;
  private localStorageKey = 'currentProductLine';

  constructor() {
  }

  getProductLine() {
    if (this.productLine) {
      return this.productLine;
    } else if (localStorage.getItem(this.localStorageKey)){
      this.productLine = localStorage.getItem(this.localStorageKey);
    } else {
      this.productLine = 'PL';
    }
    return this.productLine;
  }

  setProductLine(value: string) {
    this.productLine = value;
    localStorage.setItem(this.localStorageKey, value )
  }

  ngOnDestroy() {
    localStorage.removeItem(this.localStorageKey);
  }
}

