import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'phoneNo'
})
export class PhonePipe implements PipeTransform {

  transform(val: any) {
    // let newStr = '';
    if (val) {
      if (val.length === 3) {
        val += '-';
      }
      if (val.length === 7) {
        val += '-';
      }
      if (val.search('-') === -1 && val.length > 3) {
        val = [val.slice(0, 3), '-', val.slice(3, 6), '-', val.slice(6, 10)].join('');
      }
    }
    return val;
  }

  parse(val: any) {
    return val.replace(/-/g, '');
  }
}
