import { Component, OnInit } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-cookie-component',
  templateUrl: './cookie-component.component.html',
  styleUrls: ['./cookie-component.component.scss']
})

export class CookieComponentComponent implements OnInit {

  private expiredDate: Date;
  cookieValue: any = false;
  isLoggedIn: boolean;

  constructor( private cookieService: CookieService, private authService: AuthService) { }

  ngOnInit() {
    this.authService.loggedInObsv.subscribe((res: any) => {
      this.isLoggedIn = res;
    });
    this.cookieValue = this.cookieService.get('acceptCookie');
  }

  accept() {
    this.expiredDate = new Date();
    const year = this.expiredDate.getFullYear();
    const month = this.expiredDate.getMonth();
    const day = this.expiredDate.getDate();
    this.expiredDate = new Date(year + 100, month, day);
    this.cookieService.set( 'acceptCookie', 'true', this.expiredDate);
    this.cookieValue = true;
  }
}
