import {map, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import {Observable} from 'rxjs';
import { ExpressFnolService } from './express-fnol.service';


@Injectable()
export class ExpressGuard implements CanActivate {

    constructor(
        private router: Router,
        private expressFnolService: ExpressFnolService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
      ): Observable<boolean> {
        return this.expressFnolService.expressFnolVerified.pipe(
          map((res: boolean) => {
            if (!res) {
              this.router.navigate(['/user/policy-type']);
              return false;
            } else {
              return true;
            }
          }),);
      }
}