import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { FileLevelValidation, PreSignedURLRequest } from './file-upload.model';
import { FileUploadService } from './file-upload.service';
import { of , BehaviorSubject, Subscription} from "rxjs";
import {FormControl, Validators} from "@angular/forms";


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {

  @Input() maximumFiles: number = 5;
  @Input() totalFilesSize: number = 100; //default size set to 100MB
  // @Input() documentServiceBaseUrl: string = '';
  @Input() preSignedURLRequest: PreSignedURLRequest;
  @Input() uploadOnSelect: boolean =false;
  @Input() subSystem: any;
  @Output() uploadFiles: EventEmitter<any> = new EventEmitter<any>();
  error: FileLevelValidation;
  uploadedFiles: number = 0;
  isBrowseSelected= false;

  @HostListener('click', ['$event'])
  onMouseEnter(event: any) {
    if(event.target.id == 'fileDropRef' && !this.isBrowseSelected){
      event.preventDefault();
    }
    this.isBrowseSelected = false;
  }
  constructor(private fileUploadService: FileUploadService) { }

  ngOnInit(): void {
    this.resetValidations();
    // this.fileUploadService.documentServiceBaseUrl = this.documentServiceBaseUrl;
  }

  @ViewChild("fileDropRef") fileDropEl: ElementRef;
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files: any) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress > 0 && this.files[index].progress < 100) {
      return;
    }
    this.files.splice(index, 1);
    this.checkFilesLevelvalidation(this.files.length);
    this.emitFileResponse()
  }


  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    const filesLength = files.length + this.files.length;
    let currentPosition = 1;
    for (const item of files) {
      if (currentPosition <= this.maximumFiles && this.files.length < 5) {
        item.progress = 0;
        this.files.push(item);
        currentPosition++;
        if(this.uploadOnSelect){
          this.uploadFile(this.files.length-1);
        }
      }
    }
    this.checkFilesLevelvalidation(filesLength);
    this.fileDropEl.nativeElement.value = "";
  }

  /**
   * Checks total files and size.
   * @param filesLength
   */
  checkFilesLevelvalidation(filesLength: number) {
    this.resetValidations();
    if (filesLength > this.maximumFiles) {
      this.error.limitExceeds = true;
    }
    if(filesLength > 0){
      const totalFilesSize = this.getTotalFilesSize();
      if (totalFilesSize > this.totalFilesSize) {
        this.error.sizeExceeds = true;
      }
    }

  }

  resetValidations(): void {
    this.error = { sizeExceeds: false, limitExceeds: false };
  }

  /**
   * To bring the total files size
   * @param files
   */
  getTotalFilesSize(): number {
    let sum = this.files.map(file => file.size).reduce((a, c) => { return a + c });
    return parseFloat((sum / 1024 / 1024).toFixed(2));
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   */
  formatBytes(bytes: any) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    return parseFloat((bytes / 1024 / 1024).toFixed(2)) + " " + 'MB';
  }

  uploadFile(i: any) {
    let file = this.files[i];
    if (!file.description) {
      return;
    }
    this.preSignedURLRequest.fileType = file.type;
    this.preSignedURLRequest.filename = (this.subSystem && this.subSystem?.toLowerCase() === 'expressfnol') ? ('express-' + file.name) : file.name;
    this.preSignedURLRequest.description = file.description;
    file.inProgress = true;

    let reader: FileReader = new FileReader();
    reader.onload = function (e) {
      let target: any = e.currentTarget;
    };
    reader.readAsDataURL(file);

    this.fileUploadService.uploadFileDetails(this.preSignedURLRequest, this.subSystem).subscribe((resDataObj: any) => {
      const preSignedUrl = resDataObj.body.data.url;
      this.fileUploadService.uploadfileAWSS3(preSignedUrl, file.type, file).pipe(
          map(event => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                file.progress = Math.round(event.loaded * 100 / event.total);
                break;
              case HttpEventType.Response:
                return event;
            }
          }),
          catchError((error: HttpErrorResponse) => {
            file.inProgress = false;
            file.progress = 0;
            return of(`upload failed.`);
          })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          file.response = event.body;
          if (event && event.status == 200) {
            this.uploadedFiles = this.uploadedFiles + 1;
            file.progress = 100;
            this.files[i] = file;
            this.saveUploadedMetaData(resDataObj, this.preSignedURLRequest.fileType).subscribe((resMetaDataObj) => {
              this.files[i].response = resMetaDataObj;
              this.emitFileResponse();
            });
          }
        }
      });
    });
  }

  saveUploadedMetaData(s3ReqObj: any, currentFile: any) {
    let uploadedMetaDataInfo: any = {
      "token": s3ReqObj.body.data.token,
      "userName": localStorage.getItem('username')
    };
    return this.fileUploadService.saveUploadedMetaDataDetails(uploadedMetaDataInfo, this.subSystem)
  }

  emitFileResponse() {
    let fileResponse: any = [];
    this.files.forEach(item => {
      if (item.response) {
        fileResponse.push(item.response)
      }
    });
    let allFilesUploaded = fileResponse.length == this.files.length;
    this.uploadFiles.next({ fileResponse: fileResponse, allFilesUploaded: allFilesUploaded });
  }

  get queueLength() {
    let queueFilter = this.files.filter(item => item.response == undefined);
    return queueFilter.length;
  }

  get numberOfFiles() {
    return this.files.length;
  }

  onBrowseFileClick(){
    this.isBrowseSelected= true;
    this.fileDropEl.nativeElement.click()
  }

  clearFiles() {
    this.files = [];
  }
}
