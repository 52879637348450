import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";
import * as moment from "moment";

// Services
import { ModalHelperService } from "../shared/custom-modal/modal-helper.service";
import { GenericService } from "../services/generic.service";
import { LoaderService } from "../services/loader.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { HomeService } from "../services/home.service";
import { UserService } from "../user/services/user.service";
import { UserProfileService } from "../services/user-profile.service";
import { PhonePipe } from "../shared/phone/phone-number.pipe";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  SwiperOptions,
} from "swiper";
import {PageEvent} from "@angular/material/paginator";
import {MainMenuService} from "../main-menu/main-menu.service";
import {RenewalModalComponent} from "../shared/renewal-modal/renewal-modal.component";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import { formatDate } from "@angular/common";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  addPolicySuccess = false;
  @ViewChild("addPolicyModal") addPolicyModal: ModalDirective;
  @ViewChild("contactsManager") public contactsManager: ModalDirective;
  @ViewChild("phonenoManager") phonenoManager: ModalDirective;
  @ViewChild("notifsManager") notifsManager: ModalDirective;
  @ViewChild("removePolicyModal") removePolicyModal: ModalDirective;
  @ViewChild("addressModal") addressModal: ModalDirective;
  @ViewChild("contactsTracker") contactsTracker: ModalDirective;
  @ViewChild("securityTracker") securityTracker: ModalDirective;

  // TP48174 Modals
  @ViewChild("addMobileNumber1") addMobileNumber1: ModalDirective;
  @ViewChild("addMobileNumber2") addMobileNumber2: ModalDirective;
  @ViewChild("addMobileNumber3") addMobileNumber3: ModalDirective;

  // TP48174 : to add MOBILE type number
  updatedMobileNumber: any;
  showAddMobileNumberError = "";

  //SecurityQuestion
  securityQuestionList: any = [];
  securityAnswer: any;
  securityId = "";
  secqnHelp = " Not case sensitive ";

  // Tooltip messages
  policyHelp =
    "You must be the Named Insured to add a policy to this account. Each policy can only be attached to one THIG.com account. All policies attached to a THIG.com account must share the same e-mail address; if you need to use a different e-mail address to receive important communications about this policy, please create a new THIG.com account for it.";
  zipHelp =
    "Enter the zip code of the property insured under the policy. If your mailing address is different from the insured location, do not enter that zip or postal code.";
  agentHelp =
    "All 0 s in agent IDs are the number Zero. Your Agent ID may be printed as FL9999, FLR999, and R999. You may enter any of these formats";
  // Carousel Configuration

  // Notifications
  notificationsAlerts: any = {
    policy_alert: {
      email: true,
      text: false,
    },
    claim_alert: {
      email: true,
      text: false,
    },
  };

  userEnterPhone: any;
  phoneErrorMsg: any;
  selectPhoneType = "";
  plansSliderIndex = 1;
  selectedPolicy = null;
  policyDetails: any = [];
  addPolicyFormSubmitted = false;
  addPolicyForm = new FormGroup({
    policyNum: new FormControl("", [
      Validators.required,
      Validators.minLength(10),
      Validators.pattern("^[A-Za-z0-9]+$"),
    ]),
    agencyCode: new FormControl("", [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(6),
      Validators.pattern("^[A-Za-z0-9]+$"),
    ]),
    zipCode: new FormControl("", [
      Validators.required,
      Validators.pattern("^[0-9]{5}$"),
    ]),
  });

  // Add Policy Modal variables
  showPolicyDetails = true;
  showNotificationManager = false;
  nextSlide = 2;
  successErrMsgs = "";
  payload: any;
  notificationsPayload: any;
  notifications: any;
  policyData: any;
  renewalAmount: any;
  leftArrow = false;

  enableRemove = false;
  existingPhone: any = [];
  newPhone: any = [];
  phones: any = [];
  mobileContacts: any = [];
  contactKeys: any = [];
  duplicatePhones = 0;
  duplicatePhoneNumber: any = [];
  firstLogin: any;
  mobileType: any = [];
  showContactTracker: boolean;
  newMobileContacts: any = [];
  newEmailId: any;
  existingEmailId: any;
  notificationEmail: any;
  userName: any;
  usersessionId: any;
  serverHostName: any;
  serverTime: any;
  serverJobName: any;
  policies: any = [];
  isMobileExist: boolean;
  optOutSmsAlert: boolean;
  buildNumber: any;
  userEnterEmail: any;
  emailExist: any;
  emailErrorMsg: any;
  securityExist: boolean;
  showPasswordField = false;
  type = "password";
  securityErrorMsg: any;
  successMessage: any;
  contactInfo: any;
  errorMessage = false;
  context:any;
  customerData: any;
  edocModal: boolean;
  ssaModalActive: any;
  showFiltersPanel: boolean = false;
  selectedFilter: string;
  defaultNotificationFilter: string = 'NONARCHIVED';
  showNotificationsPanel: boolean = true;
  openNotificationWarningModal: boolean = false;
  modalMessage: any;
  selectedNotification: any;
  notificationState: any;
  unReadNotificationsCount: number;
  hidePolicyAdditionalInfo: Map<string, boolean> = new Map<string, boolean>();
  // Policy list pagination
  defaultPageIndex = 0;
  defaultPageSize = 3;
  currentPageIndex = 0;
  currentPageSize = 3;
  pageSizeOptions = [3, 6, 9, 12, 15];
  resultsLength: any;
  hidePagination: boolean = false;
  policiesPerPage: any = [];
  isAfterDate: boolean = false;

  constructor(
    private modalService: ModalHelperService,
    private mainMenuService: MainMenuService,
    private genericService: GenericService,
    private spinner: LoaderService,
    private homeService: HomeService,
    private userService: UserService,
    private userProfileService: UserProfileService,
    private router: Router,
    private phonePipe: PhonePipe,
    private dialog: MatDialog
  ) {
    const currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    this.isAfterDate = currentDate >= '2024-08-21';
  }

  ngAfterViewChecked(changes: any) {
    if (localStorage.getItem("hostServerName") != null) {
      this.serverHostName = localStorage.getItem("hostServerName");
    }
  }

  ngOnInit() {
    this.getPolicies();
    this.notificationDetails("existingUser");
    localStorage.removeItem("policyNumber");
    localStorage.removeItem("policyTerm");
    this.userName = localStorage.getItem("username");
    this.usersessionId = localStorage.getItem("clientSessionId");
    this.serverHostName = localStorage.getItem("hostServerName");
    this.buildNumber = localStorage.getItem("buildnumber");
    this.serverTime = new Date();
    this.serverJobName = localStorage.getItem("serverJob");
    this.genericService.selectedBalanceFromService = "";
  }

  activeSSAModal(event: any){
    this.ssaModalActive = event;
  }

  addPolicyFormVal() {
    this.addPolicyForm.reset();
    this.addPolicyFormSubmitted = false;
  }

  updateContactNumber() {
    this.phoneErrorMsg = null;
    if (this.selectPhoneType == "Mobile") {
      this.spinner.showLoader(true);
      this.genericService
        .validatePhone({ mobile: [{ phoneNumber: this.userEnterPhone }] })
        .subscribe(
          (validatePhoneRes: any) => {
            this.userProfileService
              .updateUserProfileData(this.payload)
              .subscribe(
                (updateUserProfileRes: any) => {
                  this.spinner.showLoader(false);
                  if (updateUserProfileRes.status === 'SUCCESS') {
                    this.mainMenuService.refreshProfileNotifications();
                  }
                },
                (error) => {
                  this.phoneErrorMsg = error?.error?.message;
                  this.spinner.showLoader(false);
                }
              );
          },
          (error) => {
            this.phoneErrorMsg = error?.error?.message;
            this.spinner.showLoader(false);
          }
        );
    } else {
      this.userProfileService.updateUserProfileData(this.payload).subscribe(
        (res: any) => {
          this.spinner.showLoader(false);
          if (res.status === 'SUCCESS') {
            this.mainMenuService.refreshProfileNotifications();
          }
        },
        (error) => {
          this.phoneErrorMsg = error?.error?.message;
          this.spinner.showLoader(false);
        }
      );
    }
  }

  getPolicies() {
    const serviceData = this.genericService.customerData;
    if (!serviceData) {
      this.spinner.showLoader(true);
      this.genericService
        .getPolicyListByCustomerName({
          username: localStorage.getItem("username"),
        })
        .subscribe({
          next: (res: any) => this.policiesSubscribeHandler(res),
          error: (e: any) => this.spinner.showLoader(false),
        });
    } else {
      this.customerData = serviceData;
      this.policyDetails = serviceData.policies;
      this.afterPolicyDetailsOperations();
    }
  }

  policiesSubscribeHandler(res: any) {
    this.spinner.showLoader(false);
    if (res.response) {
      this.customerData = res.response;
      this.errorMessage = res.response.policies.length ? false : true;
      this.policyDetails = res.response.policies;
      this.afterPolicyDetailsOperations();
      // TP48177 : Caching getCustomerByName
      this.genericService.setCustomerByNameData(res.response);
      localStorage.setItem("customerId", this.customerData.id);

      this.updatePolicyListCache();
    } else {
      this.errorMessage = true;
    }
  }

  updatePolicyListCache() {
    if (this.policies.length > 0) {
      this.genericService
        .updatePolicyListCache({
          username: localStorage.getItem("username"),
          policies: this.policies,
          is_mobile_exist: this.isMobileExist,
        })
        .subscribe(
          (res: any) => {
            this.spinner.showLoader(false);
            if (res.response) {
              console.log("updatePolicyListCache response:", res.response);
            }
          },
          (error) => {
            this.spinner.showLoader(false);
          }
        );
    }
  }

  // TP48177 : Caching getCustomerByName
  afterPolicyDetailsOperations() {
    this.resultsLength = this.policyDetails.length;
    if (this.resultsLength <= this.defaultPageSize) {
      this.hidePagination = true;
    }
    this.loadPageWithPolicies(this.currentPageIndex, this.currentPageSize);
    this.getContactInfo();
    this.contactKeys = [];
    this.policies = [];
    this.policyDetails.forEach((item: any, index: any) => {
      // Initialize the openPolicyAdditionalInfo map (for policy additional info expanded row)
      this.hidePolicyAdditionalInfo.set(item.policyNumber, true);
      this.policies.push({
        agencyCode: item.agency.code,
        policyNum: item.policyNumber,
        zipCode: item.address.postalCode,
      });
      // UAT #47761 : Solution - Verify if policy.billingEffectiveDate and terms.billing.effectiveDate are same or not
      if (item.terms.length > 1) {
        item.terms.forEach((term: any) => {
          if (
            term.status !== "RENEWAL" &&
            moment(new Date(item.billingEffectiveDate)).isSame(
              new Date(term.effectiveDate)
            )
          ) {
            term["validTerm"] = true; // additional field added NOT coming from service
          } else {
            term["validTerm"] = false;
          }
        });
      } else {
        item.terms[0]["validTerm"] = true;
      }
      // Fix ends

      item.contactInformationList.forEach((contact: any, i: any) => {
        let key = contact.contactType + contact.contactInfo;
        if (this.contactKeys.indexOf(key) == -1) {
          if (contact.contactType == "H") {
            this.mobileContacts.push({
              phoneType: "Home",
              notification: "N",
              phoneNo: contact.contactInfo,
            });
            this.existingPhone[i] = false;
          } else if (contact.contactType == "M") {
            this.mobileContacts.push({
              phoneType: "Mobile",
              notification: "Y",
              phoneNo: contact.contactInfo,
            });
            this.existingPhone[i] = false;
          } else if (contact.contactType == "W") {
            this.mobileContacts.push({
              phoneType: "Work",
              notification: "N",
              phoneNo: contact.contactInfo,
            });
            this.existingPhone[i] = false;
          }
          this.contactKeys.push(contact.contactType + contact.contactInfo);
        }
      });
    });
    this.getNotificationsList(this.defaultNotificationFilter);
  }

  toggleAdditionalInfo(policyNumber: string) {
    this.hidePolicyAdditionalInfo.set(policyNumber, !this.hidePolicyAdditionalInfo.get(policyNumber));
  }

  shouldHideAdditionalInfo(policyNumber: string): boolean {
    // @ts-ignore
    return this.hidePolicyAdditionalInfo.get(policyNumber);
  }

  pageChanged(event: PageEvent) {
    this.currentPageIndex = event.pageIndex;
    this.currentPageSize = event.pageSize;
    this.loadPageWithPolicies(event.pageIndex, event.pageSize);
  }

  loadPageWithPolicies(pageIndex: number, pageSize: number) {
    const start = pageIndex * pageSize;
    this.policiesPerPage = this.policyDetails.slice(start, start + pageSize);
  }

  // PROD #48184 - Solution: Call getContactInfo() to fetch customer care number based on DIVISIONS and display in 'Remove policy popup'
  getContactInfo() {
    this.spinner.showLoader(true);
    const rObj: any = [];
    let divisions;
    this.policyDetails.map((obj: any) => {
      rObj.push(obj.division);
      if (obj.companionPolicyDivision) {
        rObj.push(obj.companionPolicyDivision);
      }
    });
    divisions = rObj.filter(function (item: any, i: any, ar: any) {
      return ar.indexOf(item) === i;
    });
    this.userService.getContactInfo({ divisions: divisions }).subscribe(
      (res: any) => {
        this.spinner.showLoader(false);
        this.contactInfo = res;
      },
      (error) => {
        this.spinner.showLoader(false);
        console.log(error);
      }
    );
  }

  toggleShow() {
    this.showPasswordField = !this.showPasswordField;
    if (this.showPasswordField) {
      this.type = "text";
    } else {
      this.type = "password";
    }
  }

  notificationDetails(type: any) {
    this.existingPhone = [];
    this.newPhone = [];
    this.phones = [];
    this.mobileType = [];
    this.duplicatePhoneNumber = [];
    this.homeService
      .checkExistingUser({ user_name: localStorage.getItem("username") })
      .subscribe(
        (res: any) => {
          this.firstLogin = res.is_existing_user;
          this.emailExist = res.emailExist;
          this.securityExist = res.securityExist;
          if (this.firstLogin == false) {
            if (res.emailExist) {
              this.existingEmailId = res.email;
              this.notificationEmail = res.email;
            } else {
              this.existingEmailId = "";
            }
            if (!this.securityExist) {
              this.userService.getSecurityQuestionList().subscribe(
                (securityQuestionListRes: any) => {
                  this.securityQuestionList = securityQuestionListRes;
                  this.securityTracker.show();
                  this.spinner.showLoader(false);
                },
                (error) => {
                  this.spinner.showLoader(false);
                  console.log(error);
                }
              );
            } else {
              this.contactsManager.show();
            }
          } else if (type == "addPolicy") {
            this.mobileContacts = [];
            this.newMobileContacts = [];
            this.mobileType = [];
            this.emailExist = true;
            this.homeService
              .validatePolicy({
                username: localStorage.getItem("username"),
                policyDetails: this.addPolicyForm.value,
              })
              .subscribe(
                (res: any) => {
                  res.payload.newPhones.forEach((item: any) => {
                    this.newMobileContacts.push(item);
                    this.newPhone.push(false);
                  });
                  res.payload.existingPhones.forEach((item: any) => {
                    this.mobileContacts.push(item);
                    this.existingPhone.push(false);
                    if (item.notification == "Y") {
                      this.optOutSmsAlert = item.optOut;
                    }
                  });
                  this.mobileContacts.forEach((existingItem: any) => {
                    this.newMobileContacts.forEach((newContact: any, index: any) => {
                      if (
                        newContact.phoneNo == existingItem.phoneNo &&
                        newContact.phoneType == existingItem.phoneType
                      ) {
                        this.newMobileContacts.splice(index, 1);
                      }
                    });
                  });
                  if (res.payload.newEmail != res.payload.existingEmail) {
                    this.newEmailId = res.payload.newEmail;
                    this.existingEmailId = res.payload.existingEmail;
                  } else if (
                    res.payload.newEmail == res.payload.existingEmail
                  ) {
                    this.existingEmailId = res.payload.existingEmail;
                    this.newEmailId = null;
                  }
                  this.notificationEmail = res.payload.existingEmail;
                  this.contactsManager.show();
                  this.spinner.showLoader(false);
                },
                (error) => {
                  this.modalService.showConfirm("Error", error?.error?.message, "Ok");
                  this.spinner.showLoader(false);
                }
              );
          }
        },
        (error) => {
          this.spinner.showLoader(false);
        }
      );
  }

  updateEmailNotification(emailType: any) {
    this.notificationEmail = emailType;
  }

  changeSelectOption(phoneNo: any) {
    this.phones.forEach((item: any, i: any) => {
      if (phoneNo == item.phoneNo) {
        item.notification = "Y";
        this.notificationsAlerts.claim_alert.text = true;
      } else {
        item.notification = "N";
        this.notificationsAlerts.claim_alert.text = true;
      }
    });
  }

  updateSmsNotification(event: any, mobile: any, i: any, type: any) {
    this.duplicatePhones = 0;
    this.duplicatePhoneNumber = [];

    if (event) {
      this.showContactTracker = true;
      if (mobile.phoneType == "Mobile") {
        this.mobileType.push(mobile);
      }
      this.phones.push(mobile);
    } else {
      this.phones.forEach((item: any, index: any) => {
        if (
          item.phoneNo == mobile.phoneNo &&
          item.phoneType == mobile.phoneType
        ) {
          this.phones.splice(index, 1);
        }
      });
      this.mobileType.forEach((item: any, index: any) => {
        if (
          item.phoneNo == mobile.phoneNo &&
          item.phoneType == mobile.phoneType
        ) {
          this.mobileType.splice(index, 1);
        }
      });
    }

    this.duplicatePhoneNumber = this.phoneDuplicate(this.phones);
    this.duplicatePhones = this.duplicatePhoneNumber.length;
  }

  phoneDuplicate(phoneList: any) {
    let phoneListnew: any = [];
    phoneList.forEach((value: any, index: any) => {
      let exists = false;
      for (var i = index + 1; i < phoneList.length; i++) {
        if (value.phoneNo == phoneList[i].phoneNo) {
          phoneListnew.push(value.phoneNo);
        }
      }
    });
    let newArraySet = Array.from(new Set(phoneListnew));
    return newArraySet;
  }

  validatePhoneType() {
    if (this.phones.length > 0) {
      if (this.mobileType.length == 1) {
        this.phones.forEach((item: any) => {
          if (item.phoneNo == this.mobileType[0].phoneNo) {
            item.notification = "Y";
            this.notificationsAlerts.claim_alert.text = true;
          }
        });
      }

      if (this.showContactTracker && this.mobileType.length > 1) {
        this.contactsManager.hide();
        this.contactsTracker.show();
      } else {
        this.contactsManager.hide();
        this.notifsManager.show();
      }
    } else {
      this.phoneErrorMsg = "Please select at least one phone number";
    }
  }

  validateEmail() {
    this.phoneErrorMsg = null;
    this.spinner.showLoader(true);
    this.genericService
      .validateEmail({
        email: this.userEnterEmail ? this.userEnterEmail : this.existingEmailId,
        username: localStorage.getItem("username"),
        registration: false,
      })
      .subscribe(
        (res: any) => {
          if (res.is_valid == true) {
            if (this.phones.length > 0) {
              this.contactsManager.hide();
              this.contactsTracker.show();
            } else {
              this.phoneErrorMsg = "Please select at least one phone number";
            }
            this.spinner.showLoader(false);
          } else {
            this.emailErrorMsg = res.message;
            this.spinner.showLoader(false);
          }
        }
      );
  }

  clearErrorMsg() {
    this.emailErrorMsg = "";
  }

  updateNotificationDetails() {
    if (this.mobileType.length > 0) {
      this.isMobileExist = true;
    } else {
      this.isMobileExist = false;
    }
    if (this.firstLogin == false) {
      this.spinner.showLoader(true);
      this.genericService
        .validateEmail({
          email: this.userEnterEmail
            ? this.userEnterEmail
            : this.existingEmailId,
          username: localStorage.getItem("username"),
          registration: false,
        })
        .subscribe(
          (res: any) => {
            if (res.is_valid == true) {
              this.notificationEmail = this.userEnterEmail
                ? this.userEnterEmail
                : this.existingEmailId;
              this.emailErrorMsg = null;
              this.homeService
                .savePolicyDetails({
                  username: localStorage.getItem("username"),
                  policies: this.policies,
                })
                .subscribe(
                  (response: any) => {
                    this.successMessage = response.payload;
                    this.phoneErrorMsg = null;
                    if (!this.securityExist) {
                      this.updateUserSecurity();
                    }
                    if (
                      this.mobileContacts.length == 0 &&
                      this.newMobileContacts.length == 0 &&
                      this.userEnterPhone
                    ) {
                      if (this.selectPhoneType == "Mobile") {
                        this.spinner.showLoader(true);
                        this.genericService
                          .validatePhone({
                            mobile: [{ phoneNumber: this.userEnterPhone }],
                          })
                          .subscribe(
                            (successresponse) => {
                              this.contactsManager.hide();
                              this.modalService.showConfirm(
                                "Success",
                                this.successMessage,
                                "Ok"
                              );
                              this.userProfileData();
                              this.genericService.setCustomerByNameData(null);
                              this.getPolicies();
                            },
                            (error) => {
                              this.phoneErrorMsg = error?.error?.message;
                              this.spinner.showLoader(false);
                            }
                          );
                      } else {
                        this.modalService.showConfirm(
                          "Success",
                          this.successMessage,
                          "Ok"
                        );
                        this.userProfileData();
                        this.genericService.setCustomerByNameData(null);
                        this.getPolicies();
                        this.contactsManager.hide();
                      }
                    } else {
                      if (this.phones.length > 0) {
                        this.phoneErrorMsg = null;
                        this.modalService.showConfirm(
                          "Success",
                          this.successMessage,
                          "Ok"
                        );
                        this.userProfileData();
                        this.genericService.setCustomerByNameData(null);
                        this.getPolicies();
                        this.contactsManager.hide();
                      } else {
                        this.phoneErrorMsg =
                          "Please select at least one phone number";
                      }
                    }
                    this.spinner.showLoader(false);
                    if (this.phoneErrorMsg == null) {
                      this.homeService
                        .updateUserStatus({
                          user_name: localStorage.getItem("username"),
                          statusDesc: "ACTIVE",
                        })
                        .subscribe(
                          (updateUserStatusRes: any) => {
                            this.spinner.showLoader(false);
                          },
                          (error) => {
                            this.spinner.showLoader(false);
                          }
                        );
                    }
                  },
                  (error) => {
                    this.spinner.showLoader(false);
                  }
                );
            } else {
              this.emailErrorMsg = res.message;
              this.spinner.showLoader(false);
            }
          }
        );
    } else {
      this.finalSubmitForAddPolicy();
    }
  }
  checkForTextAlertConditions($event: any) {
    const mobileTypePhones = this.phones.filter(
      (phone: any) => phone.phoneType === "Mobile"
    );
    if (!mobileTypePhones.length && $event.target.checked) {
      this.notifsManager.hide();
      this.addMobileNumber1.show();
    }
  }
  finalSubmitForAddPolicy() {
    this.spinner.showLoader(true);
    this.phoneErrorMsg = null;
    this.homeService
      .addPolicy({
        username: localStorage.getItem("username"),
        policyDetails: this.addPolicyForm.value,
      })
      .subscribe(
        (addpolicyResponse: any) => {
          this.phoneErrorMsg = null;
          let userProfilepayload = {
            userName: localStorage.getItem("username"),
            email: this.notificationEmail,
            phones: this.phones,
          };
          if (
            this.mobileContacts.length == 0 &&
            this.newMobileContacts.length == 0 &&
            this.userEnterPhone
          ) {
            userProfilepayload.phones = [
              {
                phoneNo: this.userEnterPhone,
                phoneType: this.selectPhoneType,
                notification: this.selectPhoneType == "Mobile" ? "Y" : "N",
              },
            ];
          } else if (this.phones.length == 0) {
            this.phoneErrorMsg = "Please select at least one phone number";
          }
          if (this.phoneErrorMsg == null) {
            this.userProfileService
              .updateUserProfileData(userProfilepayload)
              .subscribe(
                (res: any) => {
                  let userProfilePreferencePayload = {
                    policyAlert: {
                      text: this.notificationsAlerts.policy_alert.text,
                      email: this.notificationsAlerts.policy_alert.email,
                    },
                    claimAlert: {
                      text: this.notificationsAlerts.claim_alert.text,
                      email: this.notificationsAlerts.claim_alert.email,
                    },
                    policyNum: this.addPolicyForm.controls["policyNum"].value,
                    username: localStorage.getItem("username"),
                  };
                  this.userProfileService
                    .updatePolicyPreferences(userProfilePreferencePayload)
                    .subscribe(
                      (updatePolicyPreferencesRes: any) => {
                        this.phoneErrorMsg = null;
                        this.mainMenuService.refreshProfileNotifications();
                        this.genericService.setCustomerByNameData(null);
                        this.getPolicies();
                        this.modalService.showConfirm(
                          "Success",
                          addpolicyResponse.payload,
                          "Ok"
                        );
                      },
                      (error) => {
                        this.spinner.showLoader(false);
                      }
                    );
                },
                (error) => {
                  this.spinner.showLoader(false);
                }
              );
          }
        },
        (error) => {
          this.spinner.showLoader(false);
        }
      );
  }
  addMobileTypeNumber() {
    // Step 1 : Validate the mobile number using regex
    const regex = /^\d{3}[\-]\d{3}[\-]\d{4}$/;
    if (regex.test(this.updatedMobileNumber)) {
      this.spinner.showLoader(true);
      // Step 2 : Validate the mobile number using validatePhone service
      this.genericService
        .validatePhone({ mobile: [{ phoneNumber: this.updatedMobileNumber }] })
        .subscribe(
          (res: any) => {
            this.spinner.showLoader(false);
            if (res.message) {
              this.showAddMobileNumberError = res.message;
            } else {
              const phoneArray = [
                ...this.phones,
                {
                  phoneNo: this.phonePipe.parse(this.updatedMobileNumber),
                  phoneType: "Mobile",
                  notification: "Y",
                },
              ];
              // Step 3 : Check for mobile number duplicates
              const duplicatePhones = this.phoneDuplicate(phoneArray);
              if (!duplicatePhones.length) {
                this.showAddMobileNumberError = "";
                this.addMobileNumber2.hide();
                this.phones.push({
                  phoneNo: this.phonePipe.parse(this.updatedMobileNumber),
                  phoneType: "Mobile",
                  notification: "Y",
                });
                this.updatedMobileNumber = "";
                this.addMobileNumber3.show();
              } else {
                // tslint:disable-next-line:max-line-length
                this.showAddMobileNumberError = `Duplicate phone number - ${this.updatedMobileNumber} added. Please provide unique phone number.`;
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      this.showAddMobileNumberError = "Please enter a valid mobile number";
    }
  }

  userProfileData() {
    let userProfilepayload = {
      userName: localStorage.getItem("username"),
      email: this.notificationEmail,
      phones: this.phones,
    };
    if (this.mobileContacts.length == 0 && this.newMobileContacts.length == 0) {
      userProfilepayload.phones = [
        {
          phoneNo: this.userEnterPhone,
          phoneType: this.selectPhoneType,
          notification: this.selectPhoneType == "Mobile" ? "Y" : "N",
        },
      ];
    }
    this.spinner.showLoader(true);
    this.userProfileService.updateUserProfileData(userProfilepayload).subscribe(
      (res: any) => {
        this.spinner.showLoader(false);
        if (res.status === 'SUCCESS') {
          this.mainMenuService.refreshProfileNotifications();
        }
      },
      (error) => {
        this.spinner.showLoader(false);
      }
    );
  }

  updateUserSecurity() {
    let userProfilepayload = {
      userName: localStorage.getItem("username"),
      questionId: this.securityId,
      answer: this.securityAnswer,
    };
    this.spinner.showLoader(true);
    this.userProfileService
      .updateUserSecurityData(userProfilepayload)
      .subscribe(
        (res: any) => {
          this.spinner.showLoader(false);
        },
        (error) => {
          this.spinner.showLoader(false);
        }
      );
  }

  navigateToPolicyDashboard(
    policyNumber: any,
    effectiveDate: any,
    expirationDate: any,
    pageName: any,
    address: any,
    tab?: string
  ) {
    localStorage.setItem("formattedAddress", address);
    if (effectiveDate && expirationDate) {
      const effDate = moment(effectiveDate, 'Mon-DD-YYYY').format("YYYY");
      const expDate = moment(expirationDate, 'Mon-DD-YYYY').format("YYYY");
      const policyTerm = `${effDate} - ${expDate}`;
      localStorage.setItem("policyTerm", policyTerm);
    }
    if (tab) {
      this.router.navigate([`/policy-dashboard/${policyNumber}/${pageName}`],{ queryParams: { tab } });
    } else {
      this.router.navigate([`/policy-dashboard/${policyNumber}/${pageName}`]);
    }
  }

  addPolicy() {
    if (this.addPolicyForm.valid) {
      this.addPolicyFormSubmitted = false;
      this.successErrMsgs = "";
      this.addPolicyModal.hide();
      this.spinner.showLoader(true);
      this.notificationDetails("addPolicy");
    } else {
      this.addPolicyFormSubmitted = true;
    }
  }

  removePolicy() {
    this.removePolicyModal.hide();
    this.spinner.showLoader(true);
    this.homeService
      .removePolicy({
        username: localStorage.getItem("username"),
        policyNum: this.selectedPolicy,
      })
      .subscribe(
        (res: any) => {
          this.spinner.showLoader(false);
          if (res.status === "SUCCESS") {
            this.genericService.setCustomerByNameData(null);
            this.getPolicies();
            this.modalService.showConfirm(
              "SUCCESS",
              "Policy Removed successfully!",
              "Ok"
            );
          } else if (res.status === "ERROR") {
            this.modalService.showConfirm("ERROR", res.message, "Ok");
          }
        },
        (error) => {
          this.spinner.showLoader(false);
          this.modalService.showConfirm("ERROR", error?.error?.message, "Ok");
        }
      );
  }

  showNextSlide() {
    if (this.nextSlide === 2) {
      this.showNotificationManager = true;
      this.showPolicyDetails = false;
    }
  }

  enbaleRemovePolicy() {
    return this.policyDetails.length
      ? (this.enableRemove = true)
      : (this.enableRemove = false);
  }

  getPolicyDetails(data: any, popupType: any) {
    if (this.policyDetails.length) {
      this.policyData = this.policyDetails.find(
        (policy: any) => policy.policyNumber === data
      );
      if (popupType == "renewal") {
        let selectedPolicyRenewalTerm;
        this.policyData.terms.forEach((term: any) => {
          if (term.status === 'RENEWAL') {
            selectedPolicyRenewalTerm = term;
          }
        });
        if (this.policyData.billing.renewalInvoice) {
          this.renewalAmount =
            parseFloat(this.policyData.billing.currentOutstandingBalance) +
            parseFloat(this.policyData.billing.renewalInvoice.amountDue);
        }
        this.openRenewalModal(this.policyData, selectedPolicyRenewalTerm, this.renewalAmount);
      }
      if (popupType == "address") {
        this.addressModal.show();
      }
    }
  }

  openRenewalModal(policyData: any, renewalTermData: any, renewalAmount: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      policyData: policyData,
      selectedPolicyRenewalTerm: renewalTermData,
      renewalAmount: renewalAmount
    };
    dialogConfig.width = '1150px';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(RenewalModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== undefined) { dialogRef.close(); }
    });
  }

  getLeftArrow() {
    this.leftArrow = true;
  }

  displayRenewalOffer(policy: any) {
    var renewalAmount;
    if (policy.billing.renewalInvoice) {
      renewalAmount =
        parseFloat(policy.billing.currentOutstandingBalance) +
        parseFloat(policy.billing.renewalInvoice.amountDue);
      return renewalAmount > 0;
    }
    return false;
  }


  getNotificationsList(selectedFilter: string) {
    const policiesArray: any = [];
    this.policyDetails.map((obj: any) => {
      policiesArray.push(obj.policyNumber);
    });
    this.payload = {
      policyNum: policiesArray,
      status: selectedFilter
    };
    this.userProfileService.getNotifications(this.payload).subscribe(
      (res: any) => {
        this.notificationsPayload = res.payload;
        this.policyDetails.forEach((policy: any) => {
          const notificationsList = this.notificationsPayload.notifications.find(
            (notification: any) => notification.policyNum === policy.policyNumber
          )
          policy.numUnreadNotifications = notificationsList?.numUnRead;
          policy.hasActions = notificationsList?.hasActions;
        });
      });
  }

  getNotificationsForEachPolicy(selectedFilter: string, policyNum: string) {
    this.spinner.showLoader(true);
    let payload = {
      "policyNum": ["" + policyNum + ""],
      status: selectedFilter
    }
    this.userProfileService.getNotifications(payload).subscribe(
      (res: any) => {
        this.spinner.showLoader(false);
        if (res.status === 'SUCCESS') {
          this.notificationsPayload = res.payload;
          this.notifications = this.notificationsPayload.notifications.find(
            (policy: any) => policy.policyNum === policyNum
          );
        }
      },
      error => {
        console.log(error);
        this.spinner.showLoader(false);
      });
  }

  updateNotifications(data: any, policyTermData:any, formattedAddress: any, notificationState: any) {
    if (policyTermData?.effectiveDate && policyTermData?.expirationDate) {
      const policyTerm = `${moment(policyTermData?.effectiveDate).format("YYYY")} - ${moment(
        policyTermData?.expirationDate
      ).format("YYYY")}`;
      localStorage.setItem("policyTerm", policyTerm);
    }
    this.spinner.showLoader(true);
    this.payload = {
      ids: ["" + data + ""],
      newStatus: notificationState
    };
    if (this.selectedNotification?.notification?.type === 'policy') {
      this.updatePolicyNotifications(this.payload, policyTermData, formattedAddress);
    } else {
      this.updateClaimNotifications(this.payload, policyTermData);
    }
  }

  updateClaimNotifications(data:any, formattedAddress: any) {
    this.userProfileService.updateClaimNotifications(this.payload).subscribe(
      (res: any) => {
        this.spinner.showLoader(false);
        if (res.status === 'SUCCESS') {
          if (this.selectedNotification?.type === 'openNotification') {
            this.navigateToPolicyClaim(this.notifications?.policyNum, this.selectedNotification?.notification?.entityId, formattedAddress)
          } else {
            // ToDo: remove this if server provides updated notification list in update call response
            this.getNotificationsForEachPolicy(this.selectedFilter ? this.selectedFilter : this.defaultNotificationFilter,
              this.notifications?.policyNum);
          }
        }
      },
      (error) => {
        this.spinner.showLoader(false);
      }
    );
  }

  updatePolicyNotifications(data: any, policyTermData:any, formattedAddress: any) {
    this.userProfileService.updatePolicyNotifications(data).subscribe(
      (res: any) => {
        this.spinner.showLoader(false);
        if (res.status === 'SUCCESS') {
          if (this.selectedNotification?.type === 'openNotification') {
            if (this.selectedNotification?.notification?.label === 'POLICY_DOCUMENT_AVAILABLE') {
              this.navigateToPolicyDashboard(this.notifications?.policyNum, policyTermData?.effectiveDate, policyTermData?.expirationDate, 'policy-documents', formattedAddress, 'policy')
            } else if (this.selectedNotification?.notification?.label === 'POLICY_INVOICE_AVAILABLE') {
              this.navigateToPolicyDashboard(this.notifications?.policyNum, policyTermData?.effectiveDate, policyTermData?.expirationDate, 'policy-documents', formattedAddress, 'invoice')
            } else {
              this.navigateToPolicyDashboard(this.notifications?.policyNum, policyTermData?.effectiveDate, policyTermData?.expirationDate, 'payment/make-payment', formattedAddress)
            }
          } else {
            // ToDo: remove this if server provides updated notification list in update call response
            this.getNotificationsForEachPolicy(this.selectedFilter ? this.selectedFilter : this.defaultNotificationFilter,
              this.notifications?.policyNum);
          }
        }
      },
      (error) => {
        this.spinner.showLoader(false);
      }
    );
  }

  navigateToPolicyClaim(policyNum: string, entityId: string, address: string) {
    localStorage.setItem("formattedAddress", address);
    this.router.navigate([`/policy-dashboard/${policyNum}/claims/claim-details/${entityId}`]);
  }

  showWarningModal(modalData: any) {
    this.selectedNotification = modalData;
    switch (modalData?.type) {
      case "openNotification":
        this.openNotificationWarningModal = true;
        this.modalMessage = 'This action will navigate away from this page.'
        break;
      case "delete":
        if (this.selectedNotification?.notification?.requiresAction) {
          this.modalService.showConfirm('ERROR', 'Alert cannot be deleted until required action is completed.', 'Ok');
        } else {
          this.openNotificationWarningModal = true;
          this.modalMessage = 'Are you sure you want to delete this alert? Once deleted this alert cannot be restored.'
        }
        break;
      case "archive":
        if (this.selectedNotification?.notification?.requiresAction) {
          this.modalService.showConfirm('ERROR', 'Alert cannot be archived until required action is completed.', 'Ok');
        } else {
          this.openNotificationWarningModal = true;
          this.modalMessage = 'This message will be archived.'
        }
        break;
    }
  }

  onModalConfirmClick(event: any) {
    event.stopPropagation();
    if (this.selectedNotification?.type === 'openNotification') {
      if (this.selectedNotification?.notification?.notificationStatus === 'ARCHIVED') {
        this.updateNotifications(this.selectedNotification?.notification?.id, this.selectedNotification?.policyTerm, this.selectedNotification?.formattedAddress, 'ARCHIVED');
      } else {
        this.updateNotifications(this.selectedNotification?.notification?.id, this.selectedNotification?.policyTerm, this.selectedNotification?.formattedAddress, 'READ');
      }
    } else if (this.selectedNotification?.type === 'delete') {
      this.updateNotifications(this.selectedNotification?.notification?.id, this.selectedNotification?.policyTerm, this.selectedNotification?.formattedAddress, 'DELETED');
    } else if (this.selectedNotification?.type === 'archive') {
      this.updateNotifications(this.selectedNotification?.notification?.id, this.selectedNotification?.policyTerm, this.selectedNotification?.formattedAddress, 'ARCHIVED');
    }
  }

  filterNotifications(state: any) {
    this.selectedFilter = state;
    if (this.selectedFilter) {
      this.getNotificationsForEachPolicy(state, this.notifications?.policyNum);
    }
  }

  //stopping event to parent element in order to stay open notification popup.
  stopEventPropagation(event: any) {
    event?.stopPropagation()
  }

  ngOnDestroy(): void {
    this.showNotificationsPanel = false;
  }
}
