import {Pipe, PipeTransform} from '@angular/core';

const PADDING = '000000';

@Pipe({name: 'currency'})
export class CurrencyPipe implements PipeTransform {

  private PREFIX: string;
  private DECIMAL_SEPARATOR: string;
  private SUFFIX: string;

  constructor() {
    this.PREFIX = '$' || '$$';
    this.DECIMAL_SEPARATOR = '.';
  }

  transform(value: string, fractionSize: number = 2): string | null {
    const formatValue = parseFloat(value).toFixed(2);
    let [integer, fraction = ''] = (formatValue || '').toString()
      .split('.');
    fraction = fractionSize > 0
      ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
      : '';
    integer = integer.replace(new RegExp(/[^\-0-9.]/, 'g'), '');
    return integer === '' ? null : this.PREFIX + integer + fraction;
  }

  parse(value: string, fractionSize: number = 2): string | null {
    let [integer, fraction = ''] = (value || '').replace(this.PREFIX, '')
      .replace(this.SUFFIX, '')
      .split(this.DECIMAL_SEPARATOR);
    fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
      ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
      : '';
    integer = integer.replace(new RegExp(/[^\-0-9.]/, 'g'), '');
    return integer === '' ? null : integer + fraction;
  }
}
