import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { EnvConfig } from '../../services/env-config';
import { AuthService } from '../../services/auth.service';
import { SessionTimeOutModalService } from '../../shared/sessiontimeout-modal/sessiontimeout-modal.service';
import {HttpErrorHandlerService} from '../../services/http-error-handler.service';

@Injectable()
export class UserService {
  private loggedIn = false;
  private baseUrl = this.env.getUrl().apiURL;
  private authURL = this.env.getUrl().authURL;

  constructor(
    private http: HttpClient,
    private env: EnvConfig,
    private modalService: SessionTimeOutModalService,
    private authService: AuthService,
    private errorHandler: HttpErrorHandlerService) {
  }

  setLoggedInStatus(status: any) {
    this.loggedIn = status;
  }

  isLoggedIn() {
    return this.loggedIn;
  }

  generateOAuthToken(userData: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const apiUrl = `${this.authURL}/proxyservice/security/auth/signup`;
    return this.http.post(apiUrl, { userName: userData.userid, password: userData.password }, httpOptions)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }


  // USER REGISTRATION: Validation of policy in first screen
  validatePolicy(data: any) {
    const apiUrl = `${this.baseUrl}/api/registration/validatePolicy`;
    return this.http.post(apiUrl, data)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  // USER REGISTRATION: Final user account creation
  registerUser(data: any) {
    const apiUrl = `${this.baseUrl}/api/registration/registerUser`;
    return this.http.post(apiUrl, data)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  validateUserName(data: any) {
    // const headers = new HttpHeaders({
    //   'Authorization': 'Mjkxnbjhbdcjs09'
    // });
    const apiUrl = `${this.baseUrl}/api/registration/isUserNameAndEmailAlreadyExist`;
    return this.http.post(apiUrl, data)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  login(data: any) {
    const apiUrl = `${this.baseUrl}/api/user/login`;
    return this.http.post(apiUrl, data)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  getSecurityQuestionList() {
    const apiUrl = `${this.baseUrl}/api/allsecurityquestions`;
    return this.http.get(apiUrl)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  forgetUserId(email: any) {
    let apiUrl = '';
    apiUrl = `${this.baseUrl}/api/forgotusername?email=${email}`;
    return this.http.get(apiUrl)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  getSecurityQuestionByUserID(userID: any) {
    const apiUrl = `${this.baseUrl}/api/usersecurityquestion?userName=${userID}`;
    return this.http.get(apiUrl)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  forgotPassword(data: any) {
    const apiUrl = `${this.baseUrl}/api/forgotpassword`;
    return this.http.post(apiUrl, data)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  forgotPasswordResetLink(data: any) {
    const apiUrl = `${this.baseUrl}/api/forgotpasswordresetlink`;
    return this.http.post(apiUrl, data)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  resetPassword(data: any) {
    const apiUrl = `${this.baseUrl}/api/resetpassword`;
    return this.http.post(apiUrl, data)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  validateResetPassword(data: any) {
    const apiUrl = `${this.baseUrl}/api/resetpasswordLink/${data}`;
    return this.http.post(apiUrl, null)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  getContactInfo(data: any) {
    const apiUrl = `${this.baseUrl}/api/contactUs`;
    return this.http.post(apiUrl, data)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  //VERIFY POLICY FOR EXPRESS FNOL
  fnolPolicyVerification(data: any) {
    const apiUrl = `${this.baseUrl}/api/policy/getInsuredUserDetails`;
    return this.http.post(apiUrl, data)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      )
  }

  generateExpressOAuthToken(userData: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const apiUrl = `${this.authURL}/proxyservice/security/auth/getAuthToken`;
    return this.http.post(apiUrl, userData, httpOptions)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  handleRegistrationUrl(id: any, key: any) {
    let req = {
      id: id,
      key: key
    }
      const apiUrl = `${this.baseUrl}/api/registration/validateMagicLink`;
      return this.http.post(apiUrl,req )
          .pipe(
              catchError((error) => {
                  return this.errorHandler.handleError(error, this.modalService, this.authService);
              })
          );
  }
}
