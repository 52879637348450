import { Component, OnInit, ViewChild, ViewEncapsulation, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UserProfileService } from '../services/user-profile.service';
import { LoaderService } from '../services/loader.service';
import { ModalHelperService } from '../shared/custom-modal/modal-helper.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { PhonePipe } from '../shared/phone/phone-number.pipe';
import { GenericService } from '../services/generic.service';
import {MainMenuService} from "../main-menu/main-menu.service";


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserProfileComponent implements OnInit {
  @ViewChild('eDocsSubmitConfirmation') eDocsSubmitConfirmation: ModalDirective;
  @ViewChild('personalPopup') personalPopup: ModalDirective;
  @ViewChild('contactsTracker') contactsTracker: ModalDirective;
  @ViewChild('addMobileNumber1') addMobileNumber1: ModalDirective;
  @ViewChild('addMobileNumber2') addMobileNumber2: ModalDirective;
  @ViewChild('addMobileNumber3') addMobileNumber3: ModalDirective;
  oneAtATime: boolean = true;
  modalRef: BsModalRef;
  type = "password";
  showPassword = false;
  changePasswordErrMsg = '';
  selectOption: any;
  enrollEnable: boolean;
  selectedEnroll: any[] = [];
  userprofileErrMsg = '';
  userprofileSuccessMsg = '';
  profileSection = 'profileSection';
  changePasswordFormSubmitted = false;
  userProfileFormSubmitted = false;
  userProfileData: any;
  eDocsData: any;
  eDocsTandC: any;
  eDocsSubmitMessage: any;
  payload = {};
  accountAlertsEmail = false;
  accountAlertsText = false;
  oppAlertsEmail = false;
  oppAlertsText = false;
  contactList: any;
  alertArr:any[] = [];
  alertObj = {};
  errorMsg = '';
  showContactInfo = true;
  isMobileDuplicate = false;
  primaryMobile: any;
  isChecked = false;
  name: any;
  optOutSmsAlert: boolean;
  allAlerts: any;

  changePasswordForm = new FormGroup({
    oldPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [(Validators.required),
    Validators.minLength(8)]),
    confirmNewPassword: new FormControl('', [Validators.required])
  }, this.matchPassword);

  passwordControl = {
    currentPassword: {
      type: 'password',
      showCurrentpswdIcon: false
    },
    changePassword: {
      type: 'password',
      showChangePswdIcon: false
    },
    confirmPassword: {
      type: 'password',
      showConfirmPswdIcon: false
    }
  };

  userProfileForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-z-.,' ]*$")]),
    lastName: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-z-.,' ]*$")]),
    emailId: new FormControl('', [Validators.required]),
    newPhones: this._fb.array([])
  });
  isBackspace = false;
  preferencesForm = new FormGroup({});
  showPhoneErrMsg: any;
  mobileType: any[] = [];
  panelList: any = {
    0: {
      panelName: "my-profile",
      value: true
    },
    1: {
      panelName: "my-preferences",
      value: false
    },
    2: {
      panelName: "change-password",
      value: false
    },
    3: {
      panelName: "edocs-enrollment",
      value: false
    }
  };
  userDetails = {
    "firstName": "",
    "lastName": "",
    "email": "",
    "phones": []
  }
  showNotification: boolean;
  usersessionId: any;
  serverHostName: any;
  serverTime: any;
  serverJobName: any;
  checkPswd: boolean;
  contactNum: any[] = [];
  duplicatePhoneNumber: any[] = [];
  isMobileExist: any;
  buildNumber: any;
  primaryEmail: any;
  showMobilePopup: boolean;
  ChangeOccurence: number;
  phoneNumberOccurence: number;
  notextNum: boolean;
  textNotificationSelected: any;
  preferredMobile: any;

  // TP48174 : to add MOBILE type number
  updatedMobileNumber: any;
  showAddMobileNumberError = '';
  alertTypeForMsg = 'All';
  tempDataToUpdatePreferences: { policyNo: any; type: any; alertType: any; $event: any; };
  isAllAlerts = false;

  constructor(
    private modalService: ModalHelperService,
    private mainMenuService: MainMenuService,
    private userProfileService: UserProfileService,
    private spinner: LoaderService,
    private _fb: FormBuilder,
    private genericService: GenericService,
    private route: ActivatedRoute,
    private phonePipe: PhonePipe,
    private router: Router) {
    this.route.params.subscribe(params => {
      for (const type in this.panelList) {
        if (this.panelList[type]['panelName'] == params.subname) {
          this.panelList[type]['value'] = true;
        }
        else {
          this.panelList[type]['value'] = false;
        }
      }
    });
  }

  ngAfterViewChecked(changes: any) {
    if (localStorage.getItem('hostServerName') != null) {
      this.serverHostName = localStorage.getItem('hostServerName');
    }
  }

  ngOnInit() {
    if (localStorage.oauthToken) {
      this.userDetails = {
        "firstName": '',
        "lastName": '',
        "email": '',
        "phones": []
      };
      this.getUserProfileData('getDetails');
      this.getEdocsData();
      this.getEdocsTandC();
      this.usersessionId = localStorage.getItem('clientSessionId');
      this.serverHostName = localStorage.getItem('hostServerName');
      this.serverTime = new Date();
      this.buildNumber = localStorage.getItem('buildnumber');
      this.serverJobName = localStorage.getItem('serverJob');
    } else {
      this.router.navigate(['/user/login']);
    }
  }

  initItemRows() {
    return this._fb.group({
      phoneNo: [''],
      phoneType: [''],
      notification: ['N']
    });
  }

  addContact() {
    if (this.userProfileForm.controls['newPhones'].value.length <= 3) {
      this.errorMsg = '';
      const control = <FormArray>this.userProfileForm.controls['newPhones'];
      control.push(this.initItemRows());
    }
  }

  removeContact(index: any, phoneNo: any, phoneType: any) {
    const control = <FormArray>this.userProfileForm.controls['newPhones'];
    control.removeAt(index);
    this.updateMobileType(phoneNo, phoneType, 'remove')
    this.validatePhoneNum();
    this.checkUserEnteredData();
  }

  phoneDuplicate(phoneList: any) {
    let phoneListnew: any[] = [];
    phoneList.forEach((value: any, index: any) => {
      let exists = false;
      for (var i = index + 1; i < phoneList.length; i++) {
        if (value.phoneNo == phoneList[i].phoneNo) {
          phoneListnew.push(value.phoneNo);
        }
      }
    });
    let newArraySet = Array.from(new Set(phoneListnew));
    return newArraySet;
  }

  updateMobileType(phoneNo: any, phoneType: any, type: any) {
    this.mobileType.forEach((item: any, index: any) => {
      if (item.phoneNo == phoneNo && phoneType == "Mobile") {
        if (type == "change") {
          this.mobileType.push({ 'phoneNo': phoneNo, 'phoneType': phoneType, 'notification': 'N' });
        } else if (type == 'remove') {
          this.mobileType.splice(index, 1);
        }
      } else if (item.phoneNo == phoneNo && phoneType != 'Mobile') {
        this.userProfileForm.controls['newPhones'].value.forEach((mobile: any) => {
          if (item.phoneNo == mobile.phoneNo) {
            mobile.notification = 'N';
          }
        })
        this.mobileType.splice(index, 1);
      }
    });
  }

  cancelChangesInEmailPhone() {
    this.contactList = [
      {
        phoneNo: '',
        phoneType: 'Select Type',
        notification: 'N'
      }
    ];
    this.errorMsg = '';
    this.showPhoneErrMsg = '';
    this.userProfileForm.patchValue({ emailId: '' })
    this.userProfileForm.controls['newPhones'] = this._fb.array([]);
    this.getUserProfileData('cancel');
  }

  matchPassword(frm: AbstractControl) {
    if (frm.get('newPassword')?.value === frm.get('oldPassword')?.value) {
      if (frm.get('newPassword')?.value !== frm.get('confirmNewPassword')?.value) {
        return { 'newPassword': true, 'MatchPassword': true };
      }
      return { 'newPassword': true };
    }
    if (frm.get('newPassword')?.value !== frm.get('confirmNewPassword')?.value) {
      return { 'MatchPassword': true };
    } else {
      return null;
    }
  }

  checkUserEnteredData() {
    this.ChangeOccurence = 0;
    this.phoneNumberOccurence = 0;
    if (this.userProfileForm.controls['firstName'].value != this.userDetails.firstName) {
      this.ChangeOccurence += 1;
    }
    if (this.userProfileForm.controls['lastName'].value != this.userDetails.lastName) {
      this.ChangeOccurence += 1;
    }
    if (this.userProfileForm.controls['emailId'].value != this.userDetails.email) {
      this.ChangeOccurence += 1;
    }
    this.userProfileForm.controls['newPhones'].value.forEach((item: any) => {
      if (this.userDetails.phones) {
        this.userDetails.phones.forEach((phone: any) => {
          if (phone['phoneNo'] == item['phoneNo'] && phone['phoneType'] == item['phoneType']) {
            this.phoneNumberOccurence += 1;
          }
        })
      }
    })
    if (this.phoneNumberOccurence != this.userProfileForm.controls['newPhones'].value.length) {
      this.showMobilePopup = true;
    } else {
      this.showMobilePopup = false;
    }
    if (this.ChangeOccurence == 0) {
      this.errorMsg = '';
    }
    if (this.phoneNumberOccurence == this.userProfileForm.controls['newPhones'].value.length) {
      this.showPhoneErrMsg = '';
    }
  }

  validatePhoneNum() {
    if (this.userProfileForm.controls['newPhones'].status == 'INVALID') {
      let invalidPhoneNum = 0;
      let invalidPhoneType = 0;
      let errorMessages = '';
      this.showPhoneErrMsg = '';
      const contact = <FormArray>this.userProfileForm.controls['newPhones'];
      contact.controls.forEach((item, index) => {
        const regex = /^\d{3}[\-]\d{3}[\-]\d{4}$/;
        if (item.status == 'INVALID') {
          if ((item.value.phoneNo == '' || !(regex.test(item.value.phoneNo))) && item.value.phoneType != '') {
            invalidPhoneNum += 1;
          } else if (item.value.phoneType == '' && item.value.phoneNo != '') {
            if (!(regex.test(item.value.phoneNo))) {
              invalidPhoneNum += 1;
            }
            invalidPhoneType += 1;
          } else if (item.value.phoneNo == '' && item.value.phoneType == '') {
            invalidPhoneNum += 1;
            invalidPhoneType += 1;
          }
        }
      });
      if (invalidPhoneNum >= 1 && invalidPhoneType >= 1) {
        errorMessages = 'Please enter a valid phone number and a phone type';
      } else if (invalidPhoneNum == 0 && invalidPhoneType >= 1) {
        errorMessages = 'Please select a phone type';
      } else if (invalidPhoneType == 0 && invalidPhoneNum >= 1) {
        errorMessages = 'Please enter valid phone number';
      }
      this.showPhoneErrMsg = errorMessages;
    } else {
      this.showPhoneErrMsg = null;
    }
  }

  changePassword() {
    if (this.changePasswordForm.valid && this.checkPswd) {
      this.changePasswordFormSubmitted = false;
      this.changePasswordErrMsg = '';
      this.spinner.showLoader(true);
      this.userProfileService.changePassword({ userName: localStorage.getItem('username'), ...this.changePasswordForm.value }).subscribe(
        (res: any) => {
          this.spinner.showLoader(false);
          if (res.status === 'Success') {
            this.changePasswordForm.reset();
            this.changePasswordErrMsg = '';
            this.modalService.showConfirm('SUCCESS', res.message, 'Ok');
          } else if (res.status === 'Error') {
            this.changePasswordErrMsg = res.message;
          }
        },
        error => {
          this.spinner.showLoader(false);
          this.changePasswordErrMsg = error?.error?.message || 'Error in Changing Password';

        });
    } else {
      this.changePasswordFormSubmitted = true;
    }
  }

  toggleShow(type: any) {
    if (type == 'currentPassword') {
      this.passwordControl.currentPassword.showCurrentpswdIcon = !this.passwordControl.currentPassword.showCurrentpswdIcon;
      this.passwordControl.currentPassword.type = this.passwordControl.currentPassword.showCurrentpswdIcon === true ? 'text' : 'password';
    } else if (type === 'confirmPassword') {
      this.passwordControl.confirmPassword.showConfirmPswdIcon = !this.passwordControl.confirmPassword.showConfirmPswdIcon;
      this.passwordControl.confirmPassword.type = this.passwordControl.confirmPassword.showConfirmPswdIcon === true ? 'text' : 'password';
    } else {
      this.passwordControl.changePassword.showChangePswdIcon = !this.passwordControl.changePassword.showChangePswdIcon;
      this.passwordControl.changePassword.type = this.passwordControl.changePassword.showChangePswdIcon === true ? 'text' : 'password';
    }
  }

  getUserProfileData(type: any) {
    this.spinner.showLoader(true);
    this.mobileType = [];
    this.contactList = [];
    this.primaryMobile = '';
    this.preferredMobile = '';
    this.userDetails = {
      'firstName': '',
      'lastName': '',
      'email': '',
      'phones': []
    };
    this.userProfileService.getUserProfileData(localStorage.getItem('username')).subscribe(
      (res: any) => {
        this.spinner.showLoader(false);
        if (res.payload) {
          this.userProfileData = res.payload;
          this.isMobileExist = res.payload.mobileExistforTextAlert;
          this.textNotificationSelected = res.payload.textAlertSelected;
          this.accountAlertsEmail = this.userProfileData.accountAlerts.email;
          this.accountAlertsText = this.userProfileData.accountAlerts.text;
          this.oppAlertsEmail = this.userProfileData.oppAlerts.email;
          this.oppAlertsText = this.userProfileData.oppAlerts.text;
          this.allAlerts = this.userProfileData.allAlerts;
          if (this.userProfileData.phones.length === 0) {
            // Fix for  UAT #47526: optOutSmsAlert set to false if phone array is blank from service
            this.optOutSmsAlert = false;
          } else {
            for (let i = 0; i < this.userProfileData.phones.length; i++) {
              if (this.userProfileData.phones[i].notification === 'Y') {
                this.preferredMobile = this.phonePipe.transform(this.userProfileData.phones[i].phoneNo);
                this.primaryMobile = this.phonePipe.transform(this.userProfileData.phones[i].phoneNo);
                this.optOutSmsAlert = this.userProfileData.phones[i].optOut;
              }
              this.contactList = this.userProfileData.phones;
              this.contactList.forEach((item: any, index: any) => {
                item.phoneNo = this.phonePipe.transform(item.phoneNo);
              });
              const control = <FormArray>this.userProfileForm.controls['newPhones'];
              if (control.length < this.contactList.length) {
                const diff = this.contactList.length - control.length;
                for (let i = 0; i < diff; i++) {
                  control.push(
                    this._fb.group({
                      phoneNo: [''],
                      phoneType: [''],
                      notification: ['']
                    })
                  );
                }
              }
            }
          }

          this.userProfileForm.patchValue({
            firstName: this.userProfileData.firstName,
            lastName: this.userProfileData.lastName,
            emailId: this.userProfileData.email
          });
          if (this.contactList) {
            this.userProfileForm.patchValue({
              newPhones: this.contactList
            });
          }
          this.userDetails = {
            'firstName': this.userProfileData.firstName,
            'lastName': this.userProfileData.lastName,
            'email': this.userProfileData.email,
            'phones': this.contactList
          };
          this.primaryEmail = this.userProfileData.email;
          if (this.userProfileForm.controls['newPhones'].value.length > 0) {
            this.userProfileForm.controls['newPhones'].value.forEach((item: any, index) => {
              if (item.phoneType === 'Mobile') {
                this.showNotification = true;
                this.mobileType.push(item);
                if (item.notification === 'y') {
                  this.primaryMobile = item.phoneNo;
                }
              }
            });
          }

          if (!this.showNotification) {
            this.primaryMobile = '';
          }
        }
        this.checkUserEnteredData();
      },
      error => {
        this.spinner.showLoader(false);
      });
  }

  changeAllalerts(alerts: any) {
    this.userProfileData.policyDetails.forEach((item: any, index: any) => {
      item.claimAlert.email = alerts;
      item.claimAlert.text = alerts;
    });
    if (!alerts) {
      if (this.userProfileData.accountAlerts.email) {
        this.accountAlertsEmail = false;
        this.accountAlertsText = true;
      }
      if ((this.userProfileData.accountAlerts.text) ||
        (this.userProfileData.accountAlerts.text && this.userProfileData.accountAlerts.email)) {
        this.accountAlertsEmail = true;
        this.accountAlertsText = false;
      }
    } else {
      this.accountAlertsEmail = true;
      this.accountAlertsText = true;
    }
    this.oppAlertsEmail = alerts;
    this.oppAlertsText = alerts;
    if (!this.isMobileExist && !this.optOutSmsAlert && alerts) {
      this.accountAlertsText = alerts;
      this.oppAlertsText = alerts;
      this.isAllAlerts = true;
      this.addMobileNumber1.show();
    } else {
      this.updateUserPreferences();
    }
  }

  alert(phoneNo: any) {
    alert(phoneNo);
  }

  phoneNumNotification(type: any) {
    this.showNotification = false;
    this.mobileType = [];
    this.contactNum = [];
    this.validatePhoneNum();
    this.userProfileFormSubmitted = true;
    this.userProfileForm.controls['newPhones'].value.forEach((item: any, index) => {
      this.contactNum.push(item.phoneType + item.phoneNo);
    });
    const duplicatePhoneNum = this.contactNum;
    for (let j = 0; j < this.contactNum.length; j++) {
      if (duplicatePhoneNum.hasOwnProperty(this.contactNum[j])) {
        this.contactNum.splice(j, 1);
        this.removeContact(j, this.contactNum[j], 'NULL');
        j--;
      } else {
        duplicatePhoneNum[this.contactNum[j]] = false;
      }
    }
    this.userProfileForm.controls['newPhones'].value.forEach((item: any, index) => {
      if (item.phoneType === 'Mobile' && item.phoneNo !== '') {
        this.showNotification = true;
        this.mobileType.push(item);
      }
      if (item.phoneNo === ' ' || item.phoneType === '') {
        this.showNotification = false;
      }
    });
    if (this.mobileType.length === 1) {
      this.mobileType[0].notification = 'Y';
    }
    if (this.userProfileForm.controls['emailId'].status === 'VALID' && this.userProfileForm.controls['firstName'].status === 'VALID' && this.userProfileForm.controls['lastName'].status === 'VALID') {
      this.genericService.validateEmail({
        'email': this.userProfileForm.controls['emailId'].value,
        'username': this.userProfileData.userName,
        'registration': false
      })
        .subscribe((res: any) => {
          if (res.is_valid === true) {
            this.errorMsg = '';
            if (this.showPhoneErrMsg == null) {
              const mobileNumberArray: any[] = [];
              this.mobileType.forEach((item) => {
                if (item['phoneType'] === 'Mobile') {
                  mobileNumberArray.push({ 'phoneNumber': item.phoneNo });
                }
              });
              this.genericService.validatePhone({ 'mobile': mobileNumberArray })
                .subscribe((res: any) => {
                  if (res.message != null) {
                    this.showPhoneErrMsg = res.message;
                  } else {
                    this.showPhoneErrMsg = null;
                    let NotificationMobile = false;
                    this.checkUserEnteredData();
                    if (this.primaryMobile === '') {
                      this.userProfileForm.controls['newPhones'].value.forEach((item: any, index) => {
                        if (item.notification === 'Y') {
                          NotificationMobile = true;
                        }
                      });
                      if (this.mobileType.length !== 0) {
                        this.mobileType[0].notification = 'Y';
                      }
                    } else {
                      this.notextNum = false;
                      this.userProfileForm.controls['newPhones'].value.forEach((item: any, index) => {
                        if (item.phoneNo === this.primaryMobile && item.phoneType === 'Mobile') {
                          this.notextNum = true;
                          item.notification = 'Y';
                        } else {
                          item.notification = 'N';
                        }
                      });
                      if (this.notextNum === false) {
                        NotificationMobile = false;
                        if (this.mobileType.length !== 0) {
                          this.mobileType[0].notification = 'Y';
                        }
                      } else {
                        NotificationMobile = true;
                      }
                    }
                    if (type === 'change') {
                      let NumNotification = false;
                      this.userProfileForm.controls['newPhones'].value.forEach((item: any, index) => {
                        if (item.notification === 'Y') {
                          NumNotification = true;
                        }
                      });
                      if (NumNotification === false) {
                        if (this.mobileType.length !== 0) {
                          this.mobileType[0].notification = 'Y';
                        }
                      }
                      this.contactsTracker.show();
                    } else if (NotificationMobile === false && type !== 'change' && this.mobileType.length > 1 && (this.ChangeOccurence === 0 || (this.ChangeOccurence !== 0 && (this.phoneNumberOccurence !== this.userProfileForm.controls['newPhones'].value.length)))) {
                      this.contactsTracker.show();
                    } else if (this.mobileType.length <= 1 || this.ChangeOccurence >= 1 || (this.mobileType.length > 1 && ((this.phoneNumberOccurence !== this.userProfileForm.controls['newPhones'].value.length) || this.userDetails.phones.length !== this.userProfileForm.controls['newPhones'].value.length))) {
                      this.updateUserProfile();
                    }
                  }

                },
                  error => {
                    this.showPhoneErrMsg = error?.error?.message;
                  });
            }
          } else {
            this.spinner.showLoader(false);
            this.errorMsg = res.message;
          }
        },
          error => {
            this.spinner.showLoader(false);
            this.errorMsg = error?.error?.message;
          });
    }
  }

  changeSelectOption(value: any, phoneNo: any) {
    this.userProfileForm.controls['newPhones'].value.forEach((item: any, i) => {
      if (phoneNo === item.phoneNo) {
        item.notification = 'Y';
        this.showNotification = true;
      } else {
        item.notification = 'N';
      }
    });
  }

  updateUserProfile() {
    this.validatePhoneNum();
    if (this.showPhoneErrMsg == null && this.userProfileForm.controls['emailId'].status === 'VALID'
      && this.userProfileForm.controls['firstName'].status === 'VALID' && this.userProfileForm.controls['lastName'].status === 'VALID') {
      this.userProfileFormSubmitted = false;
      this.userprofileErrMsg = '';
      this.duplicatePhoneNumber = this.phoneDuplicate(this.userProfileForm.controls['newPhones'].value);
      this.userProfileForm.controls['newPhones'].value.forEach((item: any, i) => {
        if (item.notification === 'Y') {
          this.primaryMobile = item.phoneNo;
        }
      });
      if (this.duplicatePhoneNumber.length > 0) {
        this.showPhoneErrMsg = 'Duplicate phone number(s) (';
        for (let i = 0; i < this.duplicatePhoneNumber.length; i++) {
          this.showPhoneErrMsg = this.showPhoneErrMsg + this.duplicatePhoneNumber[i];
          if (i !== this.duplicatePhoneNumber.length - 1) {
            this.showPhoneErrMsg = this.showPhoneErrMsg + ', ';
          }
        }
        this.showPhoneErrMsg = this.showPhoneErrMsg + ') are chosen. Please provide unique phone numbers.';
      } else if (this.userProfileForm.controls['newPhones'].value.length === 4 && this.mobileType.length === 0) {
        this.showPhoneErrMsg = 'If you choose to provide four phone numbers, at least one must be a mobile number.';
      } else if (this.showNotification && this.userProfileForm.controls['newPhones'].value.length === 4 && this.duplicatePhoneNumber.length === 0) {
        this.errorMsg = '';
        this.showPhoneErrMsg = null;
      }
      this.payload = {
        'userName': this.userProfileData.userName,
        'email': this.userProfileForm.controls['emailId'].value,
        'phones': this.userProfileForm.controls['newPhones'].value,
        'firstName': this.userProfileForm.controls['firstName'].value,
        'lastName': this.userProfileForm.controls['lastName'].value
      };
      if (this.errorMsg === '' && this.showPhoneErrMsg == null) {
        this.spinner.showLoader(true);
        this.userProfileService.updateUserProfileData(this.payload).subscribe(
          (res: any) => {
            this.spinner.showLoader(false);
            if (res.status === 'SUCCESS') {
              this.getUserProfileData('getDetails');
              this.mainMenuService.refreshProfileNotifications();
              this.modalService.showConfirm('SUCCESS', res.payload, 'Ok');
            } else if (res.status === 'Error') {
              this.modalService.showConfirm('ERROR', res.message, 'Ok');
            }
          },
          error => {
            // if (error.status === 'Error') {
            //   this.modalService.showConfirm('ERROR', error?.error?.message, 'Ok');
            // }
            this.spinner.showLoader(false);
          });
      }
    } else {
      this.userProfileFormSubmitted = true;
      this.spinner.showLoader(false);
    }
  }

  validatePassword(val: any) {
    const numeric = /[0-9]/;
    const upperCase = /[A-Z]/;
    const lowerCase = /[a-z]/;
    const specialCharacter = /[-!$%^&*()_+|/~=`{}[:;<>?,.@#\]"']/;
    let count = 0;
    if (val.search('<') === -1 && val.search('>') === -1 && val.search(' ') === -1) {
      if (val.match(numeric)) {
        count += 1;
      }
      if (val.match(upperCase)) {
        count += 1;
      }
      if (val.match(lowerCase)) {
        count += 1;
      }
      if (val.match(specialCharacter)) {
        count += 1;
      }
      if (count >= 3) {
        this.checkPswd = true;
      } else {
        this.checkPswd = false;
      }
    } else {
      this.checkPswd = false;
    }
  }

  enableCheckbox(checkBoxInbox: any, value: any, edoc?: any) {
    this.eDocsData.forEach((item: any, index: any) => {
      if (value && index === checkBoxInbox) {
        this.selectedEnroll[index] = true;
      } else if (!value && index === checkBoxInbox) {
        this.selectedEnroll[index] = false;
      }
    });
  }

  restrictBackspace(key: any) {
    if (key.keyCode === 32) {
      return false;
    }
    return;
  }

  navigateBetweenTabs(currentAccordion: any, changeAccordion: any) {
    this.panelList[currentAccordion].value = false;
    this.panelList[changeAccordion].value = true;
  }

  onPanelValueChange(event: any, panelId: any) {
    this.panelList[panelId].value = event;
  }

  // TP48174 : Check if MOBILE type number exist or not
  checkPreferenceConditions(policyNo: any, type: any, alertType: any, $event: any) {
    if (alertType === 'text' && !this.isMobileExist && $event.target.checked) {
      this.tempDataToUpdatePreferences = {
        policyNo, type, alertType, $event
      };
      this.updatedMobileNumber = '';
      this.addMobileNumber1.show();
    } else {
      this.updatePolicyPreferences(policyNo, type, alertType, $event);
    }
  }
  addMobileTypeNumber() {
    // Step 1 : Validate the mobile number using regex
    const regex = /^\d{3}[\-]\d{3}[\-]\d{4}$/;
    const phoneNo = this.phonePipe.transform(this.updatedMobileNumber);
    if (regex.test(phoneNo)) {
      this.spinner.showLoader(true);
      // Step 2 : Validate the mobile number using validatePhone service
      this.genericService.validatePhone({ 'mobile': [{ 'phoneNumber': phoneNo }] })
        .subscribe(
          (res: any) => {
            this.spinner.showLoader(false);
            if (res.message) {
              this.showAddMobileNumberError = res.message;
            } else {

              const phoneArray = [
                ...this.userProfileForm.controls['newPhones'].value,
                { 'phoneNo': phoneNo, 'phoneType': 'Mobile', 'notification': 'Y' }
              ];
              // Step 3 : Check for mobile number duplicates
              const duplicatePhones = this.phoneDuplicate(phoneArray);
              if (!duplicatePhones.length) {
                this.showAddMobileNumberError = '';
                this.addMobileNumber2.hide();
                const payload = {
                  'userName': this.userProfileData.userName,
                  'email': this.userProfileForm.controls['emailId'].value,
                  'phones': phoneArray,
                  'firstName': this.userProfileForm.controls['firstName'].value,
                  'lastName': this.userProfileForm.controls['lastName'].value
                };
                // Step 4 : update the mobile phone number using 'updateEmailPhone' service
                this.spinner.showLoader(true);
                this.userProfileService.updateUserProfileData(payload).subscribe(
                  result => {
                    this.spinner.showLoader(false);
                    if (result.status === 'SUCCESS') {
                      // If CHANGE-ALL-ALERTS is triggered, update all the alerts else selected alerts are updated
                      if (!this.isAllAlerts) {
                        // tslint:disable-next-line:max-line-length
                        this.updatePolicyPreferences(this.tempDataToUpdatePreferences.policyNo, this.tempDataToUpdatePreferences.type, this.tempDataToUpdatePreferences.alertType, this.tempDataToUpdatePreferences.$event);
                      } else {
                        this.updateUserPreferences();
                        this.isAllAlerts = false;
                      }
                      this.addMobileNumber3.show();
                    } else if (result.status === 'Error') {
                      this.isAllAlerts = false;
                      this.modalService.showConfirm('ERROR', res.message, 'Ok');
                    }
                  },
                  error => {
                    console.log(error);
                    this.isAllAlerts = false;
                    this.spinner.showLoader(false);
                    this.modalService.showConfirm('ERROR', 'Error in updating mobile number', 'Ok');
                  });
              } else {
                // tslint:disable-next-line:max-line-length
                this.showAddMobileNumberError = `Duplicate phone number - ${this.updatedMobileNumber} added. Please provide unique phone number.`;
              }
            }
          }, err => {
            console.log(err);
          }
        );

    } else {
      this.showAddMobileNumberError = 'Please enter a valid mobile number';
    }
  }
  updatePolicyPreferences(policyNo: any, type: any, alertType: any, $event: any) {
    if (type === 'profileAlert' && alertType === 'email' && !this.accountAlertsEmail && !this.accountAlertsText) {
      this.accountAlertsText = true;
    }
    if (type === 'profileAlert' && alertType === 'text' && !this.accountAlertsEmail && !this.accountAlertsText) {
      this.accountAlertsEmail = true;
    }
    this.userProfileData.accountAlerts.email = this.accountAlertsEmail;
    this.userProfileData.accountAlerts.text = this.accountAlertsText;
    this.userProfileData.oppAlerts.email = this.oppAlertsEmail;
    this.userProfileData.oppAlerts.text = this.oppAlertsText;
    for (let i = 0; i < this.userProfileData.policyDetails.length; i++) {

      if (this.userProfileData.policyDetails[i].policyNum === policyNo) {


        if ('policyAlert' === type) {

          if ('text' === alertType) {
            this.userProfileData.policyDetails[i].policyAlert.text = $event.target.checked;
          } else {

            this.userProfileData.policyDetails[i].policyAlert.email = $event.target.checked;
          }
        } else {
          if ('text' === alertType) {
            this.userProfileData.policyDetails[i].claimAlert.text = $event.target.checked;
          } else {
            this.userProfileData.policyDetails[i].claimAlert.email = $event.target.checked;
          }

        }
      }
    }
    // update policy preferences

    this.updateUserPreferences();
  }

  updateUserPreferences() {
    this.userProfileData.accountAlerts.email = this.accountAlertsEmail;
    this.userProfileData.accountAlerts.text = this.accountAlertsText;
    this.userProfileData.oppAlerts.email = this.oppAlertsEmail;
    this.userProfileData.oppAlerts.text = this.oppAlertsText;
    if (this.preferencesForm.valid) {
      this.userProfileFormSubmitted = false;
      this.userprofileErrMsg = '';
      this.spinner.showLoader(true);
      this.userProfileService.updateUserProfilePreferenceData(this.userProfileData).subscribe(
        (res: any) => {
          this.spinner.showLoader(false);
          if (res) {
            this.allAlerts = res.allAlerts;
            this.getUserProfileData('getDetails');
            this.mainMenuService.refreshProfileNotifications();
          }
        },
        error => {
          this.spinner.showLoader(false);
        });
    } else {
      this.userProfileFormSubmitted = true;
    }
  }

  getEdocsData() {
    this.spinner.showLoader(true);
    this.genericService.getPolicyListByCustomerName({
      'username': localStorage.getItem('username')
    }
    ).subscribe(
      (res: any) => {
        this.spinner.showLoader(false);
        if (res.response) {
          this.eDocsData = res.response.policies;
          this.eDocsData.forEach((item: any, index: any) => {
            this.selectedEnroll[index] = item.eDocsEnrolled;
          });
        }
      },
      error => {
        this.spinner.showLoader(false);
      });
  }

  getEdocsTandC() {
    this.spinner.showLoader(true);
    this.genericService.termsAndConditions('EDOC').subscribe(
      (res: any) => {
        this.spinner.showLoader(false);
        this.eDocsTandC = res;
      },
      error => {
        this.spinner.showLoader(false);
      });
  }

  eDocsEnrollment(data: any) {
    this.spinner.showLoader(true);
    this.payload = {
      'T&Cid': this.eDocsTandC,
      'eDocs': {
        'policyNum': data,
        'username': this.userProfileData.userName,
        'userId' : localStorage.getItem("customerId"),
      }
    };
    this.userProfileService.edocsEnroll(this.payload).subscribe(
      (res: any) => {
        this.spinner.showLoader(false);
        if (res.payload) {
          this.eDocsSubmitMessage = res.payload;
          if (res.status === 'SUCCESS') {
            this.modalService.showConfirm('SUCCESS', res.payload, 'Ok');

          } else if (res.status === 'Error') {
            this.modalService.showConfirm('ERROR', res.message, 'Ok');
          }
          for (let i = 0; i < this.eDocsData.length; i++) {

            if (this.eDocsData[i].policyNumber === data) {

              this.eDocsData[i].eDocsEnrolled = true;
            }

          }

        }
      },
      error => {
        this.spinner.showLoader(false);

      });
  }

  eDocsWithdraw(data: any, i: any) {
    this.spinner.showLoader(true);
    this.payload = {
      'policyNum': data,
      'username': this.userProfileData.userName
    };
    this.userProfileService.edocsWithdraw(this.payload).subscribe(
      (res: any) => {
        this.selectedEnroll[i] = false;
        this.spinner.showLoader(false);
        if (res.payload) {
          this.eDocsSubmitMessage = res.payload;
          if (res.status === 'SUCCESS') {
            this.modalService.showConfirm('SUCCESS', res.payload, 'Ok');

          } else if (res.status === 'Error') {
            this.modalService.showConfirm('ERROR', res.message, 'Ok');
          }
          for (let i = 0; i < this.eDocsData.length; i++) {

            if (this.eDocsData[i].policyNumber === data) {
              this.selectedEnroll[i] = false;
              this.eDocsData[i].eDocsEnrolled = false;
            }

          }
        }
      },
      error => {
        this.spinner.showLoader(false);

      });
  }


}
