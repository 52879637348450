import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { EnvConfig } from './env-config';
import { AuthService } from './auth.service';
import { SessionTimeOutModalService } from '../shared/sessiontimeout-modal/sessiontimeout-modal.service';
import { BehaviorSubject } from "rxjs";
import { HttpErrorHandlerService } from './http-error-handler.service';

@Injectable()
export class GenericService {

  constructor(
    private http: HttpClient,
    private env: EnvConfig,
    private authService: AuthService,
    private modalService: SessionTimeOutModalService,
    private errorHandler: HttpErrorHandlerService
  ) {
  }

  private baseUrl = this.env.getUrl().apiURL;
  public selectedBalanceFromService = ''; // PAYMENTS: To track payment balance type
  public customerData: any;
  public customerDataAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getPolicyListByCustomerName(data: any) {
    const apiUrl = `${this.baseUrl}/proxyservice/oasis/api/customer/getCustomerByUsername`;
    return this.http.post(apiUrl, data,
      {
        observe: 'response',
        ...this.env.getHttpHeaders()
      }
    ).pipe(
      map((res: any) => {
        localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
        return res.body;
      }),
      catchError((error) => {
        return this.errorHandler.handleError(error, this.modalService, this.authService);
      })
    );
  }

  // This service is to store the value onload of home.component.
  setCustomerByNameData(value: any) {
    this.customerData = value;
    if (value) {
      this.customerDataAvailable.next(true);
    }
  }

    updatePolicyListCache(data: any) {
        const apiUrl = `${this.baseUrl}/api/policy/savePolicyDetails`;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
                }
            ).pipe(
                map((res: any) => {
                    localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService);
                })
            );
    }

  // GET Chat button status
  getChatButtonStatus() {
    const params = new HttpParams()
      .set('callback', 'jQuery111104005125091641719_1540186402784');
    const apiUrl = `${this.env.getUrl().chatConfigURL}/chatConfiguration/ajaxButtonStatus`;
    return this.http.jsonp(apiUrl, params.toString())
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error) => {
          return error;
        })
      );
  }

  // Terms and condition service
  termsAndConditions(moduleName: any) {
    const apiUrl = `${this.baseUrl}/api/termsandconditions/${moduleName}`;
    return this.http.get(apiUrl,
      {
        observe: 'response',
        ...this.env.getHttpHeaders()
      }
    ).pipe(
      map((res: any) => {
        localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
        return res.body;
      }),
      catchError((error) => {
        return this.errorHandler.handleError(error, this.modalService, this.authService);
      })
    );
  }

  // Terms Acceptance service
  termsAcceptance(data: any) {
    const apiUrl = `${this.baseUrl}/api/termsacceptance`;
    return this.http.post(apiUrl, data,
      {
        observe: 'response',
        ...this.env.getHttpHeaders()
      }
    ).pipe(
      map((res: any) => {
        localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
        return res.body;
      }),
      catchError((error) => {
        return this.errorHandler.handleError(error, this.modalService, this.authService);
      })
    );
  }

  // Log Info
  getLogInfo() {
    const apiUrl = `${this.baseUrl}/info`;
    return this.http.get(apiUrl,
      {
        observe: 'response',
        ...this.env.getHttpHeaders()
      }
    ).pipe(
      map(data => {
        localStorage.hostServerName = data.headers.get('X-SERVER-HOSTNAME');
        return data.body;
      }),
      catchError((error) => {
        return this.errorHandler.handleError(error, this.modalService, this.authService);
      })
    );
  }

  // Validate-email
  validateEmail(data: any) {
    const apiUrl = `${this.baseUrl}/api/user/validateEmail`;
    return this.http.post(apiUrl, data)
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  // Validate-phone
  validatePhone(data: any) {
    const apiUrl = `${this.baseUrl}/api/user/validatePhone`;
    return this.http.post(apiUrl, data
    )
      .pipe(
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  //download file
  downloadFile(path: any) {
    const apiUrl = `${this.baseUrl}/api/document/download`;
    const reqBody = {
      downloadPath: path
    };
    return this.http.post(apiUrl, reqBody, {
      observe: 'response',
      responseType: 'blob', headers: {
        'x-session-id': localStorage.clientSessionId,
        'x-user-id': localStorage.username,
        'Authorization': `Bearer ${localStorage.oauthToken}`
      }
    })
      .pipe(
        map((res: any) => {
          localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
          return res.body;
        }),
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  signSubscriberSavingsAgreement(data: any) {
    const apiUrl = `${this.baseUrl}/proxyservice/oasis/api/customer/signSubscriberSavingsAgreement`;
    return this.http.post(apiUrl, data,
        {
          observe: 'response',
          ...this.env.getHttpHeaders()
        }
    ).pipe(
        map((res: any) => {
          localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
          return res.body;
        }),
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
    );
  }

  retrieveLossRunReport(data: any) {
    const apiUrl = `${this.baseUrl}/proxyservice/oasis/api/customerPolicyInfo/createLossRunReport`;
    return this.http.post(apiUrl, data, {
      observe: 'response',
      ...this.env.getHttpHeaders()
    }).pipe(
        map((res: any) => {
          localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
          if (res.body['success'] === true) {
            return res.body;
          } else {
            this.authService.turnOffSpinner();
            this.modalService.showConfirm('INFO',res.body['errors'][0], 'Close' );
          }
        }),
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
    );
  }
}
