import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Application Modules
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

// Library Modules
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CheckboxModule } from 'primeng/checkbox';
import { SidebarModule } from 'primeng/sidebar';
import { SwiperModule } from 'swiper/angular';
import { PopoverModule } from "ngx-bootstrap/popover";
import { TooltipModule } from 'ngx-bootstrap/tooltip';

// Components
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

// Services
import { GenericService } from './services/generic.service';
import { AuthGuard } from './services/guards/auth-guard.service';
import { UserService } from './user/services/user.service';
import { LoaderService } from './services/loader.service';
import { UserProfileService } from './services/user-profile.service';
import { HomeService } from './services/home.service';
import { AuthService } from './services/auth.service';
import { EnvConfig } from './services/env-config';
import { CookieService } from 'ngx-cookie-service';
import { DynamicScriptLoaderService } from "./services/dynamic-script-loader.service";
import { OneIncService } from './services/one-inc.service';
import { HttpErrorHandlerService } from './services/http-error-handler.service';
import { ProductLineService } from "./services/product-line.service";
import { SubscriberSavingsAgreementModalComponent } from "./home/subscriber-savings-agreement-modal/subscriber-savings-agreement-modal.component";
import { ExpressGuard } from './user/services/express-guard';
import { GoDigitalModalComponent } from './home/go-digital-modal/go-digital-modal.component';
import { DialogModule } from 'primeng/dialog';
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon'
import { MatPaginatorModule } from "@angular/material/paginator";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { MatButtonModule } from "@angular/material/button";
import { ApmErrorHandler, ApmModule, ApmService } from '@elastic/apm-rum-angular';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SubscriberSavingsAgreementModalComponent,
    MainMenuComponent,
    UserProfileComponent,
    GoDigitalModalComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ModalModule.forRoot(),
    ButtonsModule.forRoot(),
    AccordionModule.forRoot(),
    AppRoutingModule,
    BrowserModule,
    SharedModule,
    MatMenuModule,
    MatIconModule,
    CheckboxModule,
    SidebarModule,
    SwiperModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    DialogModule,
    MatRadioModule,
    MatCheckboxModule,
    MatPaginatorModule,
    CollapseModule,
    MatButtonModule,
    ApmModule
  ],
  providers: [
    EnvConfig,
    GenericService,
    AuthGuard,
    ExpressGuard,
    AuthService,
    UserService,
    HomeService,
    UserProfileService,
    LoaderService,
    CookieService,
    OneIncService,
    ProductLineService,
    DynamicScriptLoaderService,
    HttpErrorHandlerService,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [EnvConfig],
      multi: true,
    },
    ApmService,
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler
    }
   ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

export function startupServiceFactory(startupService: EnvConfig): Function {
  return () => startupService.getEnvironment();
}
