import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {RouterModule} from '@angular/router';


import {CustomModalComponent} from './custom-modal/custom-modal.component';
import {CookieComponentComponent} from './cookie-component/cookie-component.component';
import {FileUploadComponent} from "./file-upload/file-upload.component";
import {SessionTimeOut} from './sessiontimeout-modal/sessiontimeout-modal.component';
import {ModalHelperService} from './custom-modal/modal-helper.service';
import {SessionTimeOutModalService} from './sessiontimeout-modal/sessiontimeout-modal.service';
import {CurrencyFormatterDirective} from './currency/currency-formatter.directive';
import {FileUploadService} from "./file-upload/file-upload.service";
import {CurrencyPipe} from './currency/currency-formatter.pipe';
import {CardNumValidatorDirective} from './card-validator/card-num-validator.directive';
import {ExpiryDateValidatorDirective} from './card-validator/expiry-date-validator.directive';
import {PhonePipe} from './phone/phone-number.pipe';
// Modules
import {ModalModule} from 'ngx-bootstrap/modal';
import {JoinPipe} from './join-pipe/join.pipe';
import {RestrictXssCharacterDirective} from './generic/restrict-xss-character.directive';
import {MatDialogModule} from "@angular/material/dialog";
import {UpdateRecurringConfirmDialogComponent} from './update-recurring-confirm-dialog/update-recurring-confirm-dialog.component';
import {UpdateRecurringConfirmService} from './update-recurring-confirm-dialog/update-recurring-confirm.service';
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatExpansionModule} from "@angular/material/expansion";
import {NotificationsComponent } from './notifications/notifications.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {DialogModule} from 'primeng/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatTabsModule} from "@angular/material/tabs";
import {RenewalModalComponent} from './renewal-modal/renewal-modal.component';
import {DragDropModule} from "@angular/cdk/drag-drop";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ModalModule.forRoot(),
    MatDialogModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatInputModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    DialogModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    DragDropModule
  ],
  declarations: [
    CustomModalComponent,
    CookieComponentComponent,
    FileUploadComponent,
    SessionTimeOut,
    CurrencyFormatterDirective,
    CardNumValidatorDirective,
    ExpiryDateValidatorDirective,
    PhonePipe,
    JoinPipe,
    RestrictXssCharacterDirective,
    UpdateRecurringConfirmDialogComponent,
    NotificationsComponent,
    RenewalModalComponent
  ],
  providers: [
    ModalHelperService,
    SessionTimeOutModalService,
    UpdateRecurringConfirmService,
    FileUploadService,
    DatePipe,
    CurrencyPipe,
    PhonePipe],
  exports: [
    CurrencyFormatterDirective,
    CookieComponentComponent,
    FileUploadComponent,
    CardNumValidatorDirective,
    ExpiryDateValidatorDirective,
    PhonePipe, JoinPipe, RestrictXssCharacterDirective,
    UpdateRecurringConfirmDialogComponent,
    NotificationsComponent, RenewalModalComponent]
})
export class SharedModule {
}
