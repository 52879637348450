import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { EnvConfig } from './env-config';
import { AuthService } from './auth.service';
import { SessionTimeOutModalService } from '../shared/sessiontimeout-modal/sessiontimeout-modal.service';
import {HttpErrorHandlerService} from './http-error-handler.service';

@Injectable()
export class HomeService {

  constructor(
    private http: HttpClient,
    private env: EnvConfig,
    private modalService: SessionTimeOutModalService,
    private authService: AuthService,
    private errorHandler: HttpErrorHandlerService) {
  }

  private baseUrl = this.env.getUrl().apiURL;

  addPolicy(data: any) {
    const apiUrl = `${this.baseUrl}/api/policy/addPolicy`;
    return this.http.post(apiUrl, data,
      {
        observe: 'response',
        ...this.env.getHttpHeaders()
      })
      .pipe(
        map((res: any) => {
          localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
          return res.body;
        }),
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  removePolicy(data: any) {
    const apiUrl = `${this.baseUrl}/api/policy/removePolicy`;
    return this.http.post(apiUrl, data,
      {
        observe: 'response',
        ...this.env.getHttpHeaders()
      })
      .pipe(
        map((res: any) => {
          localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
          return res.body;
        }),
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  validatePolicy(data: any) {
    const apiUrl = `${this.baseUrl}/api/policy/validatePolicy`;
    return this.http.post(apiUrl, data,
      {
        observe: 'response',
        ...this.env.getHttpHeaders()
      })
      .pipe(
        map((res: any) => {
          localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
          return res.body;
        }),
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  checkExistingUser(data: any) {
    const apiUrl = `${this.baseUrl}/api/isExistingUser`;
    return this.http.post(apiUrl, data, {
      observe: 'response',
      ...this.env.getHttpHeaders()
    })
      .pipe(        map((res: any) => {
          localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
          return res.body;
        }),
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  savePolicyDetails(data: any) {
    const apiUrl = `${this.baseUrl}/api/policy/savePolicyDetails`;
    return this.http.post(apiUrl, data,
      {
        observe: 'response',
        ...this.env.getHttpHeaders()
      })
      .pipe(
        map((res: any) => {
          localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
          return res.body;
        }),
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }

  updateUserStatus(data: any) {
    const apiUrl = `${this.baseUrl}/api/updateUserStatus`;
    return this.http.post(apiUrl, data, {
      observe: 'response',
      ...this.env.getHttpHeaders()
    })
      .pipe(
        map((res: any) => {
          localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
          return res.body;
        }),
        catchError((error) => {
          return this.errorHandler.handleError(error, this.modalService, this.authService);
        })
      );
  }
}
