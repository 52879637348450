import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExpressFnolService {

  expressFnolVerified = new BehaviorSubject<boolean>(false);
  isExpressFnol = new BehaviorSubject<boolean>(false);
  enrollForCP = new BehaviorSubject<any>(null);
  verificationInfoDetails: any;
  policyDetails: any;
  
  constructor() { }

  setVerificationInfoDetails(data: any) {
    this.verificationInfoDetails = data;
  }

  getVerificationInfoDetails() {
    return this.verificationInfoDetails;
  }

  setPolicyDetails(data: any) {
    this.policyDetails = data;
  }

  getPolicyDetails() {
    return this.policyDetails;
  }
}
