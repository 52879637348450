import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import { throwError } from 'rxjs';
import {EnvConfig} from './env-config';
import {LoaderService} from './loader.service';

@Injectable()
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  private baseUrl = this.env.getUrl();
  loggedInObsv = this.loggedIn.asObservable();

  constructor(
    private router: Router,
    private http: HttpClient,
    private env: EnvConfig,
    private spinner: LoaderService) {
  }

  get isLoggedIn() {
    if (localStorage.oauthToken) {
      this.loggedIn.next(true);
    } else {
      this.loggedIn.next(false);
    }
    return this.loggedIn.asObservable();
  }

  set isLoggedIn(value) {
    value ? this.loggedIn.next(true) : this.loggedIn.next(false);
  }

  login(policyNumber: any) {
    this.loggedIn.next(true);
    if (localStorage.isUpdateRecurringPayment == 'true' && policyNumber != undefined){
      this.router.navigate([`/policy-dashboard/${policyNumber}/payment/payment-summary`],
              { queryParams: { autoPaymentUpdate: 'true'} });
    }
    else{
      this.router.navigate(['/home']);
    }
   
  }

  logout() {
    const apiUrl = `${this.baseUrl.authURL}/proxyservice/security/oauth/logout`;
    return this.http.post(apiUrl, {}, this.env.getHttpHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }

  logOutForSessionExpiry(headers: any) {
    const apiUrl = `${this.baseUrl.authURL}/proxyservice/security/oauth/logout`;
    return this.http.post(apiUrl, {}, headers)
      .pipe(
        catchError(this.handleError)
      );
  }

  turnOffSpinner() {
    this.spinner.showLoader(false);
  }

  setLogInStatus(value: any) {
    this.loggedIn.next(value);
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error.error);
  }
}
