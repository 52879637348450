import {Directive, HostListener} from '@angular/core';

@Directive({selector: '[restrict-xss-char]'})

export class RestrictXssCharacterDirective {
  @HostListener('keypress', ['$event']) onKeyPress(val: any) {
    const notAllowedChars = ['<', '>', '%', '&'];
    if (notAllowedChars.find(item => item === val.key)) {
      return false;
    }
    return;
  }
}
